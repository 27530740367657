import { useEffect, useState } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { onAuthStateChanged } from 'firebase/auth';
import { Loader } from 'react-overlay-loader';
import Header from './header';
import Nav from './nav';
import { auth } from '../../config';

// Constants for app bar heights
const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 92;

// Styled components
const StyledRoot = styled('div')({
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden',
});

const Main = styled('div')(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
  minHeight: '100%',
  paddingTop: APP_BAR_MOBILE + 24,
  paddingBottom: theme.spacing(10),
  [theme.breakpoints.up('lg')]: {
    paddingTop: APP_BAR_DESKTOP + 24,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}));

export default function DashboardLayout() {
  const [authenticated, setAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false); // Declare 'open' state variable

  useEffect(() => {
    // Listen for changes in the user's authentication state
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setAuthenticated(!!user); // Use !! to convert user to a boolean
      setLoading(false); // Mark loading as complete
    });

    // Clean up the listener when the component unmounts
    return () => unsubscribe();
  }, []);

  // If loading, show a loading spinner
  if (loading) {
    return <Loader fullPage loading={loading} />;
  }

  // If not authenticated, redirect to the login page
  if (!authenticated) {
    return <Navigate to="/login" />;
  }

  // If authenticated, render the main dashboard layout
  return (
    <StyledRoot>
      <Header onOpenNav={() => setOpen(true)} />
      <Nav openNav={open} onCloseNav={() => setOpen(false)} />
      <Main>
        <Outlet />
      </Main>
    </StyledRoot>
  );
}
