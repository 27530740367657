import { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Container,
  Divider,
  Unstable_Grid2 as Grid,
  Stack,
  TextField,
} from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { useFormik } from 'formik';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { toast } from 'react-toastify';
import { Loader } from 'react-overlay-loader';
import { firestoreDB } from '../../config';
import { BRICKS_SIZE, LAYING_TYPE, BRICKS_TYPES, STATUS } from '../../utils/constants';
import { AddBrickLayoutSchema } from '../../validations/BrickLayout.schema';

const ViewBrickLayout = () => {
  const [brickLayoutData, setBrickLayoutData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isEditable, setIsEditable] = useState(false);
  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    const getBrickLayoutDetails = async () => {
      try {
        const docRef = doc(firestoreDB, 'bricklayingcost', id);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          setBrickLayoutData(docSnap.data());
        }
      } catch (e) {
        console.log('e >>', e);
      } finally {
        setIsLoading(false);
      }
    };
    getBrickLayoutDetails();
  }, []);

  const formik = useFormik({
    initialValues: {
      ...brickLayoutData,
      status: brickLayoutData.status || 'active',
      brickType: brickLayoutData.brickType || Object.keys(BRICKS_TYPES)[0],
    },
    enableReinitialize: true,
    validationSchema: AddBrickLayoutSchema,
    onSubmit: async (values) => {
      try {
        setIsLoading(true);
        const docRef = doc(firestoreDB, 'bricklayingcost', id);
        await updateDoc(docRef, {
          ...values,
          updatedAt: new Date(),
        });
        toast.success('BrickLayout updated successfully');
        navigate('/dashboard/bricklayout');
      } catch (error) {
        console.log(error);
        toast.error('Something went wrong');
      } finally {
        setIsLoading(false);
      }
    },
  });

  return (
    <>
      <Loader fullPage loading={isLoading} />
      <Box
        component="main"
        sx={{
          flexGrow: 1,
        }}
      >
        <Container maxWidth="lg">
          <Stack spacing={3}>
            <Stack direction="row" alignItems="center" justifyContent="end" mb={5}>
              <Button variant="contained" onClick={() => setIsEditable(!isEditable)}>
                {isEditable ? 'Cancel' : 'Edit'}
              </Button>
            </Stack>
            <div>
              <Grid container spacing={3}>
                <Grid xs={12}>
                  <form>
                    <Card>
                      <CardHeader subheader="" title="BrickLayout Details" />
                      <CardContent sx={{ pt: 3 }}>
                        <Box sx={{ m: -1.5 }}>
                          <Grid container spacing={3}>
                            <Grid xs={12} md={6}>
                              <TextField
                                fullWidth
                                label="Brick Size (In Inch)"
                                name="brickSize"
                                value={formik.values.brickSize}
                                onChange={formik.handleChange}
                                select
                                SelectProps={{ native: true }}
                                error={formik.touched.brickSize && Boolean(formik.errors.brickSize)}
                                InputLabelProps={{ shrink: true }}
                                disabled={!isEditable}
                              >
                                {Object.keys(BRICKS_SIZE).map((type) => (
                                  <option key={type} value={type}>
                                    {BRICKS_SIZE[type]}
                                  </option>
                                ))}
                              </TextField>
                            </Grid>
                            <Grid xs={12} md={6}>
                              <TextField
                                fullWidth
                                label="Laying Type"
                                name="layingType"
                                value={formik.values.layingType}
                                onChange={formik.handleChange}
                                select
                                SelectProps={{ native: true }}
                                error={formik.touched.layingType && Boolean(formik.errors.layingType)}
                                InputLabelProps={{ shrink: true }}
                                disabled={!isEditable}
                              >
                                {Object.keys(LAYING_TYPE).map((type) => (
                                  <option key={type} value={type}>
                                    {LAYING_TYPE[type]}
                                  </option>
                                ))}
                              </TextField>
                            </Grid>
                            <Grid xs={12} md={6}>
                              <TextField
                                fullWidth
                                label="Per Brick Cost"
                                placeholder="60"
                                type="per_brick_cost"
                                name="perBrickCost"
                                onChange={formik.handleChange}
                                error={formik.touched.perBrickCost && Boolean(formik.errors.perBrickCost)}
                                helperText={formik.touched.perBrickCost && formik.errors.perBrickCost}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                value={formik.values.perBrickCost}
                                disabled={!isEditable}
                              />
                            </Grid>
                            <Grid xs={12} md={6}>
                            <TextField
                                fullWidth
                                label="Status"
                                name="status"
                                value={formik.values.status}
                                onChange={formik.handleChange}
                                select
                                SelectProps={{ native: true }}
                                error={formik.touched.status && Boolean(formik.errors.status)}
                                InputLabelProps={{ shrink: true }}
                                disabled={!isEditable}
                              >
                                {Object.keys(STATUS).map((type) => (
                                  <option key={type} value={type}>
                                    {STATUS[type]}
                                  </option>
                                ))}
                              </TextField>
                            </Grid>
                            <Grid xs={12} md={6}>
                              <TextField
                                fullWidth
                                label="Brick Type"
                                name="brickType"
                                value={formik.values.brickType}
                                onChange={formik.handleChange}
                                select
                                SelectProps={{ native: true }}
                                error={formik.touched.brickType && Boolean(formik.errors.brickType)}
                                InputLabelProps={{ shrink: true }}
                                disabled={!isEditable}
                              >
                                {Object.keys(BRICKS_TYPES).map((type) => (
                                  <option key={type} value={type}>
                                    {BRICKS_TYPES[type]}
                                  </option>
                                ))}
                              </TextField>
                            </Grid>
                          </Grid>
                        </Box>
                      </CardContent>
                      <Divider />
                      {isEditable ? (
                        <div className="buttons">
                          <CardActions sx={{ justifyContent: 'flex-start', padding: '1rem 2rem 2rem 2rem' }}>
                            <Button variant="contained" onClick={formik.handleSubmit}>
                              Save details
                            </Button>
                          </CardActions>
                        </div>
                      ) : (
                        <></>
                      )}
                    </Card>
                  </form>
                </Grid>
              </Grid>
            </div>
          </Stack>
        </Container>
      </Box>
    </>
  );
};

export default ViewBrickLayout;
