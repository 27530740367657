import React, { useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { calculateTransportCost } from '../utils/calculator';
import Loader from '../components/Loader';
import usePerkmrates from '../hooks/usePerkmrates';
import useWheelsData from '../hooks/useWheelsData';
import useFetchBrickPrices from '../hooks/useFetchBrickPrices';
import '../style.css';
import logo1 from '../assets/icon-1.png';
import logo2 from '../assets/icon-2.png';
import logo3 from '../assets/icon-3.png';
import TransportCost from '../components/TransportCost';

const TransportPage = () => {
  const [formData, setFormData] = useState({
    factoryLocation: '695506',
    destinationPincode: '',
    vehicleType: '12',
  });
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [finalResult, setFinalResult] = useState({});
  const perkmRates = usePerkmrates();
  const wheelsData = useWheelsData();
  const [brickPricesData] = useFetchBrickPrices(setLoading);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    setErrors({
      ...errors,
      [name]: '',
    });
  };

  const validateForm = () => {
    const newErrors = {};

    if (!formData.factoryLocation) {
      newErrors.factoryLocation = 'Factory Location is required';
    }

    if (!formData.destinationPincode) {
      newErrors.destinationPincode = 'Destination Pincode is required';
    }

    if (!formData.vehicleType) {
      newErrors.vehicleType = 'Vehicle Type is required';
    }

    return newErrors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const newErrors = validateForm();

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    setLoading(true);

    try {
      const service = new window.google.maps.DistanceMatrixService();
      service.getDistanceMatrix(
        {
          origins: ['695506'],
          destinations: [formData.destinationPincode],
          travelMode: 'DRIVING',
          unitSystem: window.google.maps.UnitSystem.METRIC,
          avoidHighways: false,
          avoidTolls: false,
        },
        (response, status) => {
          if (response.rows[0].elements[0].status === 'NOT_FOUND') {
            setErrors((prevErrors) => ({
              ...prevErrors,
              destinationPincode: `Please enter valid pincode`,
            }));
            setFinalResult({})
            return;
          }
          if (status === 'OK') {
            const distanceValue = response.rows[0].elements[0].distance.text;
            const margin = 5;
            let distance = parseFloat(distanceValue.replace(/[, ]/g, '')) + 10;
          
            distance += 10;

            const perKmRateData =
              perkmRates.filter((rate) => rate.distanceRangeFrom <= distance && rate.distanceRangeTo >= distance) || [];

            const transportCostData = calculateTransportCost(
              perKmRateData,
              margin,
              distance,
              wheelsData,
              brickPricesData
            );
           
            setFinalResult(transportCostData.sort((a, b) => a.wheel - b.wheel));
          }
        }
      );
    } catch (error) {
      console.error('Calculation Error:', error);
      setErrors({ calculation: 'An error occurred during the calculation.' });
    } finally {
      setLoading(false);
    }
  };
  return (
    <div className="container">
      <div className="header-block">
        <div className="header-logo-1">
          <img src={logo1} alt="" />
        </div>
        <div className="header-text">Dolfix Transport Cost Calculator</div>
        <div className="header-logo-2">
          <img src={logo2} alt="" />
          <img src={logo3} alt="" />
        </div>
      </div>

      <div className="form-container">
        <Loader loading={loading} />

        {/* <h2>Quotation Form</h2> */}
        <Form onSubmit={handleSubmit} className="custom-form">
        <Form.Group controlId="factoryLocation" className="mb-20">
            <Form.Label>Factory Location Pincode</Form.Label>
            <Form.Control
              type="text"
              disabled
              name="factoryLocation"
              value="695506"
              placeholder="Enter Pincode"
            />
            {errors.factoryLocation && <div className="error-message">{errors.factoryLocation}</div>}
          </Form.Group>

          <Form.Group controlId="destinationPincode" className="mb-20">
            <Form.Label>Destination Pincode</Form.Label>
            <Form.Control
              type="text"
              name="destinationPincode"
              value={formData.destinationPincode}
              onChange={handleChange}
              placeholder="Enter Pincode"
            />
            {errors.destinationPincode && <div className="error-message">{errors.destinationPincode}</div>}
          </Form.Group>

          <Button variant="primary" type="submit">
            Get Quotation
          </Button>
        </Form>
      </div>

      <div className="table-container table-data-sm mb-60">
        <h3>RESULT OF TRANSPORT COST PER TON (Excluding GST)</h3>
        <div className="brick-rates">
          <div className="brick-type">
            <div className="brick-sizes">
              {finalResult.length > 0 ? (
                finalResult.map((cost, index) => (
                  <div key={index} className="brick-size">
                    <p>{cost.wheel}W</p>
                    <p className="price">
                      <span> ₹ </span>
                      {cost.perTonRate}
                    </p>
                  </div>
                ))
              ) : (
                <>
                  {' '}
                  <div className="brick-size">
                    <p>6W</p>
                    <p className="price">
                      <span> ₹ </span>0
                    </p>
                  </div>
                  <div className="brick-size">
                    <p>10W</p>
                    <p className="price">
                      <span> ₹ </span>0
                    </p>
                  </div>
                  <div className="brick-size">
                    <p>12W</p>
                    <p className="price">
                      <span> ₹ </span>0
                    </p>
                  </div>
                  <div className="brick-size">
                    <p>14W</p>
                    <p className="price">
                      <span> ₹ </span>0
                    </p>
                  </div>
                  <div className="brick-size">
                    <p>16W</p>
                    <p className="price">
                      <span> ₹ </span>0
                    </p>
                  </div>
                  <div className="brick-size">
                    <p>18W</p>
                    <p className="price">
                      <span> ₹ </span>0
                    </p>
                  </div>
                  <div className="brick-size">
                    <p>22W</p>
                    <p className="price">
                      <span> ₹ </span>0
                    </p>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>

      <TransportCost
        type="excludeGST"
        title="RESULT OF TRANSPORT COST PER BRICK (Excluding GST)"
        finalResult={finalResult}
      />
      <TransportCost
        type="includeGST"
        title="RESULT OF TRANSPORT COST PER BRICK (Include GST)"
        finalResult={finalResult}
      />
    </div>
  );
};

export default TransportPage;
