import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
// @mui
import {
  Button,
  Card,
  Container,
  IconButton,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';

import {  doc,  updateDoc } from 'firebase/firestore';
import { Loader } from 'react-overlay-loader';
import { toast } from 'react-toastify';
// hooks
import useFetchBricksLayout from '../../hooks/useFetchBricksLayout';
// components
import Iconify from '../../components/iconify';
import Scrollbar from '../../components/scrollbar';
// sections
import { UserListToolbar, UserListHead } from '../../sections/@dashboard/user';
import { firestoreDB } from '../../config';
// utils
import { getComparator, applySortFilter } from '../../utils/index';


const TABLE_HEAD = [
  { id: 'brickSize', label: 'Brick Size', alignRight: false },
  { id: 'layingType', label: 'Laying Type', alignRight: false },
  { id: 'perBrickCost', label: 'Per Brick Cost', alignRight: false },
  { id: 'status', label: 'Status', alignRight: false},
  { id: 'action', label: 'Action', alignRight: false },
];

const BrickLayoutPage = () => {
  const [tableSettings, setTableSettings] = useState({
    page: 0,
    order: 'asc',
    orderBy: 'brickSize',
    rowsPerPage: 5,
  });
  const [filterName, setFilterName] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [brickLaying, setBrickLaying] = useState([]);
  const [selected, setSelected] = useState([]);

  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('brickSize');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [wheelsData] = useFetchBricksLayout(setIsLoading);
  const navigate = useNavigate();

  useEffect(() => {
    if (wheelsData?.length > 0) {
      setBrickLaying(wheelsData);
    }
  }, [wheelsData]);

  const handleRequestSort = (event, property) => {
    const isAsc = tableSettings.orderBy === property && tableSettings.order === 'asc';
    const newOrder = isAsc ? 'desc' : 'asc';
    setTableSettings({
      ...tableSettings,
      order: newOrder,
      orderBy: property,
    });
  };

  const handleChangePage = (event, newPage) => {
    setTableSettings({ ...tableSettings, page: newPage });
  };

  const handleChangeRowsPerPage = (event) => {
    setTableSettings({
      ...tableSettings,
      page: 0,
      rowsPerPage: parseInt(event.target.value, 10),
    });
  };

  const handleFilterByName = (event) => {
    setTableSettings({ ...tableSettings, page: 0 });
    setFilterName(event.target.value);
  };

  const emptyRows = tableSettings.page > 0
    ? Math.max(0, (1 + tableSettings.page) * tableSettings.rowsPerPage - brickLaying?.length)
    : 0;

  const filteredBrickLaying = applySortFilter(
    brickLaying,
    getComparator(tableSettings.order, tableSettings.orderBy),
    filterName,
    'layingType'
  );

  const isNotFound = !filteredBrickLaying.length && !!filterName;

  const handleView = (id) => {
    navigate(`/dashboard/bricklayout/${id}`);
  };

  const handleAdd = () => {
    navigate('/dashboard/bricklayout/new');
  };

  const handleDelete = async (id) => {
    try {
      setIsLoading(true);
      const docRef = doc(firestoreDB, 'bricklayingcost', id);
      await updateDoc(docRef, {
        isDeleted: true,
        updatedAt: new Date(),
      });
      const updatedWheels = brickLaying.filter((brick) => brick.id !== id);
      setBrickLaying(updatedWheels);
      toast.success('Brick layout deleted successfully');
    } catch (error) {
      console.error(error);
      toast.error('Something went wrong');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Loader fullPage loading={isLoading} />
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Bricks Layout
          </Typography>
          <Button variant="contained" startIcon={<Iconify icon="eva:plus-fill" />} onClick={handleAdd}>
            New Brick layout
          </Button>
        </Stack>
        <Card>
          <UserListToolbar numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName} />
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
             
              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={brickLaying.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                />
                <TableBody>
                  {filteredBrickLaying.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {
                    const { id,brickSize, layingType,perBrickCost, status } = row;
                    return (
                      <TableRow hover key={id} tabIndex={-1} role="checkbox">
                        <TableCell align="left">{brickSize}</TableCell>
                        <TableCell align="left">{layingType}</TableCell>
                        <TableCell align="left">{perBrickCost}</TableCell>
                        <TableCell align="left">{status?.toUpperCase()}</TableCell>
                        <TableCell align="left">
                          <Tooltip title="View" placement="top">
                            <IconButton size="large" color="inherit" onClick={() => handleView(id)}>
                              <Iconify icon="typcn:eye" />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="Delete" placement="top">
                            <IconButton
                              size="large"
                              color="inherit"
                              onClick={() => handleDelete(id)}
                            >
                              <Iconify icon={'ic:baseline-delete'} />
                            </IconButton>
                          </Tooltip>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>

                {brickLaying?.length === 0 && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <Paper
                          sx={{
                            textAlign: 'center',
                          }}
                        >
                          <Typography variant="h6" paragraph>
                            Not found
                          </Typography>

                          <Typography variant="body2">
                            No results found for &nbsp;
                            <strong>&quot;{filterName}&quot;</strong>.
                            <br /> Try checking for typos or using complete words.
                          </Typography>
                        </Paper>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={brickLaying.length}
            rowsPerPage={tableSettings.rowsPerPage}
            page={tableSettings.page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>
    </>
  );
};

export default BrickLayoutPage;
