import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';
import {
  Button,
  Card,
  Container,
  IconButton,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  Tooltip,
  Typography,
  TextField, // Import TextField for search
} from '@mui/material';
import { doc, updateDoc, collection, addDoc, query, where, getDocs } from 'firebase/firestore';
import { Loader } from 'react-overlay-loader';
import { toast } from 'react-toastify';
import useFetchMargins from '../../hooks/useFetchMargins';
import Iconify from '../../components/iconify';
import Scrollbar from '../../components/scrollbar';
import { UserListToolbar, UserListHead } from '../../sections/@dashboard/user';
import { firestoreDB } from '../../config';
import { getComparator, applySortFilter } from '../../utils/index';

const TABLE_HEAD = [
  { id: 'truckType', label: 'Wheel Type', alignRight: false },
  { id: 'distanceRangeFrom', label: 'Distance From', alignRight: false },
  { id: 'distanceRangeTo', label: 'Distance To', alignRight: false },
  { id: 'per_km_price', label: 'Per KM Price', alignRight: false },
  { id: 'fixed_cost', label: 'Fix Price (upto 100KM)', alignRight: false },
  { id: 'action', label: 'Action', alignRight: false },
];

export default function MarginsPage() {
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('truckType'); // Change the default sorting column
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const [marginsData] = useFetchMargins(setIsLoading);
  const [margins, setMargins] = useState([]);

  useEffect(() => {
    if (marginsData?.length > 0) {
      setMargins(marginsData);
    }
  }, [marginsData]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
  
    // Sort the data based on the selected property and order
    const sortedMargins = [...margins].sort((a, b) => {
      if (isAsc) {
        return a[property] < b[property] ? -1 : 1;
      } 
      return b[property] < a[property] ? -1 : 1;
    });
  
    setMargins(sortedMargins);
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const handleView = (id) => {
    navigate(`/dashboard/margins/${id}`);
  };

  const handleAdd = () => {
    navigate('/dashboard/margins/new');
  };

  const handleDelete = async (id) => {
    try {
      setIsLoading(true);
      const docRef = doc(firestoreDB, 'margins', id);
      await updateDoc(docRef, {
        isDeleted: true,
        updatedAt: new Date(),
      });
      const updatedMargins = margins.filter((margin) => margin.id !== id);
      setMargins(updatedMargins);
      toast.success('Margin deleted successfully');
    } catch (error) {
      console.log(error);
      toast.error('Something went wrong');
    } finally {
      setIsLoading(false);
    }
  };

  const handleDuplicate = async (id) => {
    const selectedRow = margins.find((row) => row.id === id);

    if (selectedRow) {
      const { id, ...duplicatedRow } = selectedRow;

      try {
        setIsLoading(true);
        const marginsRef = collection(firestoreDB, 'margins');
        const newDocRef = await addDoc(marginsRef, {
          ...duplicatedRow,
          createdAt: new Date(),
          updatedAt: new Date(),
          isDeleted: false,
        });
        const newId = newDocRef.id;
        setMargins([...margins, { id: newId, ...duplicatedRow }]);
        toast.success('Margin duplicated successfully');
      } catch (error) {
        console.log(error);
        toast.error('Something went wrong');
      } finally {
        setIsLoading(false);
      }
    }
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - margins?.length) : 0;

  // Filter the data based on the search input
  // const filterData = async () => {
  //   if (filterName === '') {
  //     setFilterName('');
  //     return;
  //   }
  //   const filteredMargins = [];
  //   const marginsQuery = query(collection(firestoreDB, 'margins'), where('truckType', '==', filterName));
  //   const querySnapshot = await getDocs(marginsQuery);
  //   querySnapshot.forEach((doc) => {
  //     filteredMargins.push(doc.data());
  //   });
  //   setMargins(filteredMargins);
  // };

  const filterData = async () => {
    if (filterName === '') {
      setFilterName('');
      return;
    }

    const filteredMargins = margins.filter((row) => row.truckType.toLowerCase().includes(filterName.toLowerCase()));

    setMargins(filteredMargins);
  };

  const filteredMargins = applySortFilter(margins, getComparator(order, orderBy), filterName, 'distanceRangeFrom');

  return (
    <>
      <Loader fullPage loading={isLoading} />
      <Helmet>
        <title> User | Minimal UI </title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Margins
          </Typography>
          <Button variant="contained" startIcon={<Iconify icon="eva:plus-fill" />} onClick={handleAdd}>
            New Margin
          </Button>
        </Stack>

        <Card>
          <UserListToolbar numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName}>
            {/* <StatusFilter
              setOpenFilter={setOpenFilter}
              setAnchorEl={setAnchorEl}
              Boolean={Boolean}
              openFilter={openFilter}
              anchorEl={anchorEl}
              currentStatus={currentStatus}
              setCurrentStatus={setCurrentStatus}
            /> */}
          </UserListToolbar>

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={margins.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                />
                <TableBody>
                  {filteredMargins.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {
                    const {
                      id,
                      truckType,
                      distanceRangeFrom,
                      distanceRangeTo,
                      per_km_price: perKmPrice,
                      fixed_cost: fixedCost,
                    } = row;
                    return (
                      <TableRow hover key={id} tabIndex={-1} role="checkbox">
                        <TableCell align="left">{truckType} Wheel</TableCell>
                        <TableCell align="left">{distanceRangeFrom} KM</TableCell>
                        <TableCell align="left">{distanceRangeTo} KM</TableCell>
                        <TableCell align="left">{perKmPrice || 0}</TableCell>
                        <TableCell align="left">{fixedCost || 0}</TableCell>
                        <TableCell align="left">
                          <Tooltip title="View" placement="top">
                            <IconButton size="large" color="inherit" onClick={() => handleView(id)}>
                              <Iconify icon="typcn:eye" />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="Delete" placement="top">
                            <IconButton size="large" color="inherit" onClick={() => handleDelete(id)}>
                              <Iconify icon={'ic:baseline-delete'} />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="Duplicate" placement="top">
                            <IconButton size="large" color="inherit" onClick={() => handleDuplicate(id)}>
                              <Iconify icon="eva:copy-fill" />
                            </IconButton>
                          </Tooltip>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>

                {margins?.length === 0 && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <Paper
                          sx={{
                            textAlign: 'center',
                          }}
                        >
                          <Typography variant="h6" paragraph>
                            Not found
                          </Typography>

                          <Typography variant="body2">
                            No results found for &nbsp;
                            <strong>&quot;{filterName}&quot;</strong>.
                            <br /> Try checking for typos or using complete words.
                          </Typography>
                        </Paper>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={margins.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>
    </>
  );
}
