import React, { useState, useEffect } from 'react';
import { Button, Form } from 'react-bootstrap';
import * as html2canvas from 'html2canvas';
import useFetchBricksLayout from '../hooks/useFetchBricksLayout';

import { applyGST, calculateTransportCost, convertValue } from '../utils/calculator';
import Loader from '../components/Loader';
import usePerkmrates from '../hooks/usePerkmrates';
import useWheelsData from '../hooks/useWheelsData';
import useFetchBrickPrices from '../hooks/useFetchBrickPrices';
import BrickSizeTable from '../components/BrickSizeTable';
import '../style.css';
import { TRUCK_TYPES } from '../utils/constants';
import logo1 from '../assets/icon-1.png';
import logo2 from '../assets/icon-2.png';
import logo3 from '../assets/icon-3.png';
import iconPdf from '../assets/icon-pdf.svg';
import HtmlToPdf from './HtmlToPdf';
import BrickLayingCost from '../components/BrickLayingCost';

const { jsPDF } = require('jspdf');

const Home = () => {
  const [formData, setFormData] = useState({
    factoryLocation: '695506',
    destinationPincode: '',
    vehicleType: '12',
  });
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [finalResult, setFinalResult] = useState([]);
  const [layingCost, setLayinCost] = useState([]);
  const resultRef = React.createRef();
  const perkmRates = usePerkmrates();
  const wheelsData = useWheelsData();
  const [brickPricesData] = useFetchBrickPrices(setLoading);
  const [brickLaying] = useFetchBricksLayout(setLoading);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
    setErrors((prevErrors) => ({ ...prevErrors, [name]: '' }));
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formData.factoryLocation) {
      newErrors.factoryLocation = 'Factory Location is required';
    }
    if (!formData.destinationPincode) {
      newErrors.destinationPincode = 'Destination Pincode is required';
    }
    if (!formData.vehicleType) {
      newErrors.vehicleType = 'Vehicle Type is required';
    }
    return newErrors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const newErrors = validateForm();
    setErrors((prevErrors) => ({
      ...prevErrors,
      destinationPincode: '',
    }));

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }
    setLoading(true);
    try {
      const service = new window.google.maps.DistanceMatrixService();
      const destinationPincode = `${formData?.destinationPincode},India`;

      service.getDistanceMatrix(
        {
          origins: ['695506,India'],
          destinations: [destinationPincode],
          travelMode: 'DRIVING',
          unitSystem: window.google.maps.UnitSystem.METRIC,
          avoidHighways: false,
          avoidTolls: false,
        },
        (response, status) => {
          if (response.rows[0].elements[0].status === 'NOT_FOUND') {
            setErrors((prevErrors) => ({
              ...prevErrors,
              destinationPincode: `Please enter valid pincode`,
            }));
            setFinalResult({});
            return;
          }
          if (status === 'OK') {
            const distanceValue = response?.rows[0]?.elements[0]?.distance.text;
            const distance = parseFloat(distanceValue.replace(/[, ]/g, '')) + 10;

            console.log("distance >>>",distance);
            // console.log("perkmRates >>>",perkmRates);
            const margin = distance < 600 ? 5 : 2;
            const selectedVehicleType = formData.vehicleType;
            const perKmRateData =
              perkmRates.find(
                (rate) =>
                  rate.distanceRangeFrom <= distance &&
                  rate.distanceRangeTo >= distance &&
                  rate.truckType.toString() === selectedVehicleType.toString()
              ) || {};

            const brickPriceObj = brickPricesData.reduce(
              (result, item) => {
                if (
                  (item.brickQuality === 'standard' ||
                    item.brickQuality === 'prime' ||
                    item.brickQuality === 'super') &&
                  (item.brickType === 'm' || item.brickType === 'interlock')
                ) {
                  const key = `${item.brickQuality[0].toUpperCase()}${item.brickQuality.slice(1)}`;
                  const sizeKey = `${item.brickSize === '8' ? 'eightInch' : 'sixInch'}${
                    item.brickType === 'm' ? 'M' : 'Interlock'
                  }`;
                  result[key][sizeKey] = parseInt(item.brickPrice, 10) || 0;
                }
                return result;
              },
              {
                Standard: { eightInchM: 0, sixInchM: 0, eightInchInterlock: 0, sixInchInterlock: 0 },
                Prime: { eightInchM: 0, sixInchM: 0, eightInchInterlock: 0, sixInchInterlock: 0 },
                Super: { eightInchM: 0, sixInchM: 0, eightInchInterlock: 0, sixInchInterlock: 0 },
              }
            );

            const transportCost = calculateTransportCost(perKmRateData, margin, distance, wheelsData, brickPricesData);
            // console.log("transportCost >>",transportCost)
            // console.log("brickPriceObj >>",brickPriceObj)
            const finalRateObjWithGST = {
              Standard: {
                eightInchInterlock:
                  brickPriceObj.Standard.eightInchInterlock > 0
                    ? convertValue(applyGST(transportCost[0].eightInch + brickPriceObj.Standard.eightInchInterlock))
                    : 0,
                eightInchM:
                  brickPriceObj.Standard.eightInchM > 0
                    ? convertValue(applyGST(transportCost[0].eightInch + brickPriceObj.Standard.eightInchM))
                    : 0,
                sixInchInterlock:
                  brickPriceObj.Standard.sixInchInterlock > 0
                    ? convertValue(applyGST(transportCost[0].sixInch + brickPriceObj.Standard.sixInchInterlock))
                    : 0,
                sixInchM:
                  brickPriceObj.Standard.sixInchM > 0
                    ? convertValue(applyGST(transportCost[0].sixInch + brickPriceObj.Standard.sixInchM))
                    : 0,
              },
              Prime: {
                eightInchInterlock:
                  brickPriceObj.Prime.eightInchInterlock > 0
                    ? convertValue(applyGST(transportCost[0].eightInch + brickPriceObj.Prime.eightInchInterlock))
                    : 0,
                eightInchM:
                  brickPriceObj.Prime.eightInchM > 0
                    ? convertValue(applyGST(transportCost[0].eightInch + brickPriceObj.Prime.eightInchM))
                    : 0,
                sixInchInterlock:
                  brickPriceObj.Prime.sixInchInterlock > 0
                    ? convertValue(applyGST(transportCost[0].sixInch + brickPriceObj.Prime.sixInchInterlock))
                    : 0,
                sixInchM:
                  brickPriceObj.Prime.sixInchM > 0
                    ? convertValue(applyGST(transportCost[0].sixInch + brickPriceObj.Prime.sixInchM))
                    : 0,
              },
              Super: {
                eightInchInterlock:
                  brickPriceObj.Super.eightInchInterlock > 0
                    ? convertValue(applyGST(transportCost[0].eightInch + brickPriceObj.Super.eightInchInterlock))
                    : 0,
                eightInchM:
                  brickPriceObj.Super.eightInchM > 0
                    ? convertValue(applyGST(transportCost[0].eightInch + brickPriceObj.Super.eightInchM))
                    : 0,
                sixInchInterlock:
                  brickPriceObj.Super.sixInchInterlock > 0
                    ? convertValue(applyGST(transportCost[0].sixInch + brickPriceObj.Super.sixInchInterlock))
                    : 0,
                sixInchM:
                  brickPriceObj.Super.sixInchM > 0
                    ? convertValue(applyGST(transportCost[0].sixInch + brickPriceObj.Super.sixInchM))
                    : 0,
              },
            };
            setFinalResult(finalRateObjWithGST);
          } else {
            console.log('response >>', response, status);
          }
        }
      );
    } catch (error) {
      console.error('Calculation Error:', error);
      setErrors({ calculation: 'An error occurred during the calculation.' });
    } finally {
      setLoading(false);
    }
  };
  
  const handlePdf = () => {
    window.scrollTo(0, 0);
    setTimeout(() => {
      const divToPrint = document.querySelector('#page');
      html2canvas(divToPrint).then((canvas) => {
        const imgData = canvas.toDataURL('image/png');
        const imgWidth = 190;
        const pageHeight = 290;
        const imgHeight = (canvas.height * imgWidth) / canvas.width;
        let heightLeft = imgHeight;
        // eslint-disable-next-line new-cap
        const doc = new jsPDF('pt', 'mm');
        let position = 0;
        doc.addImage(imgData, 'PNG', 10, 0, imgWidth, imgHeight + 25);
        heightLeft -= pageHeight;
        while (heightLeft >= 0) {
          position = heightLeft - imgHeight;
          doc.addPage();
          doc.addImage(imgData, 'PNG', 10, position, imgWidth, imgHeight + 25);
          heightLeft -= pageHeight;
        }
        doc.save('download.pdf');
      });
    }, 1000);
  };

  // BrickLaying

  useEffect(() => {
    const getLayingPrice = async () => {
      const layingData = [];
      brickLaying.map((laying) =>
        layingData.push({
          layingType: laying.layingType,
          brickSize: laying.brickSize,
          layingCost: laying.perBrickCost,
        })
      );
      setLayinCost(layingData);
      getLayingPrice();
    };
  }, [brickLaying]);

  return (
    <>
      <div className="container">
        <div className="header-block">
          <div className="header-logo-1">
            <img src={logo1} alt="" />
          </div>
          <div className="header-text">Dolfix Bricks Pricing Plan</div>
          <div className="header-logo-2">
            <img src={logo2} alt="" />
            <img src={logo3} alt="" />
          </div>
        </div>

        <div className="form-container">
          <Loader loading={loading} />

          {/* <h2>Quotation Form</h2> */}
          <Form onSubmit={handleSubmit} className="custom-form">
            <Form.Group controlId="factoryLocation" className="mb-20">
              <Form.Label>Factory Location Pincode</Form.Label>
              <Form.Control type="text" disabled name="factoryLocation" value="695506" placeholder="Enter Pincode" />
              {errors.factoryLocation && <div className="error-message">{errors.factoryLocation}</div>}
            </Form.Group>
            <Form.Group controlId="destinationPincode" className="mb-20">
              <Form.Label>Destination Pincode</Form.Label>
              <Form.Control
                type="text"
                name="destinationPincode"
                value={formData.destinationPincode}
                onChange={handleChange}
                placeholder="Enter Pincode"
              />
              {errors.destinationPincode && <div className="error-message">{errors.destinationPincode}</div>}
            </Form.Group>

            <Form.Group controlId="vehicleType" className="mb-20">
              <Form.Label>Vehicle Type</Form.Label>
              <Form.Select
                aria-label="Vehicle Type"
                name="vehicleType"
                value={formData.vehicleType}
                onChange={handleChange}
              >
                <option value="">Select Vehicle Type</option>
                {TRUCK_TYPES.map((type) => (
                  <option key={type} value={type}>
                    {type}
                  </option>
                ))}
              </Form.Select>
              {errors.vehicleType && <div className="error-message">{errors.vehicleType}</div>}
            </Form.Group>

            <Button variant="primary" type="submit">
              Get Quotation
            </Button>
          </Form>
        </div>
        <div className="table-container mb-40">
          <div className="brick-rates">
            <div className="brick-type">
              <h3 className="with-height">Brick type</h3>
              <div className="brick-sizes">
                <div className="brick-size alternative" key="eightInch">
                  <div className='interlock' style={{  }}>
                    <p style={{}}>InterLock</p>
                    <p style={{  }}>M</p>
                  </div>
                </div>
                <div className="brick-size alternative" key="sixInch">
                <div className='interlock'  style={{  }}>
                    <p style={{ }}>InterLock</p>
                    <p style={{  }}>M</p>
                  </div>
                </div>
              </div>
            </div>
            <BrickSizeTable type="Brick Type" data={finalResult} />
            <BrickSizeTable type="Standard" data={finalResult} />
            <BrickSizeTable type="Super" data={finalResult} />
            <BrickSizeTable type="Prime" data={finalResult} />
          </div>
          <h3 style={{ textAlign: 'center', marginTop: '10px' }}>Brick Laying Price</h3>
          <div className="brick-rates">
            {['Standard', 'Premium'].map((layingType) => (
              <BrickLayingCost layingType={layingType} brickData={brickLaying} />
            ))}
          </div>
          <button className="btn btn-secondary" onClick={handlePdf}>
            <img src={iconPdf} alt="" /> Download PDF
          </button>
        </div>
      </div>
      <div style={{ opacity:"0" }}>
        <div
          id="page"
          style={{
            width: '100%',
            background: '#f4f6fc',
            margin: '0 auto',
            padding: '15px',
            border: 'solid 1px #cecece',
          }}
        >
          {<HtmlToPdf resultRef={resultRef} resultObj={finalResult} brickLaying={brickLaying} />}
        </div>
      </div>
    </>
  );
};
export default Home;
