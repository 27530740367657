import { useState } from 'react';
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Container,
  Divider,
  Unstable_Grid2 as Grid,
  Stack,
  TextField,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { toast } from 'react-toastify';
import { addDoc, collection } from 'firebase/firestore';
import { Loader } from 'react-overlay-loader';
import { firestoreDB } from '../../config';
import { TRUCK_TYPES } from '../../utils/constants';
import { AddMarginSchema } from "../../validations/Margin.schema";

const AddMargin = () => {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      distanceRangeFrom: '',
      distanceRangeTo: '',
      truckType: TRUCK_TYPES[0] || '',
      per_km_price: 0,
      fixed_cost: 0,
    },
    validationSchema: AddMarginSchema,
    onSubmit: async (values) => {
      try {
        setIsLoading(true);
        const marginsRef = collection(firestoreDB, 'margins');
        await addDoc(marginsRef, {
          ...values,
          createdAt: new Date(),
          updatedAt: new Date(),
          isDeleted: false,
        });
        toast.success('Margin added successfully');
        navigate('/dashboard/margins');
      } catch (error) {
        console.log(error);
        toast.error('Something went wrong');
      } finally {
        setIsLoading(false);
      }
    },
  });

  return (
    <>
      <Loader fullPage loading={isLoading} />
      <Box
        component="main"
        sx={{
          flexGrow: 1,
        }}
      >
        <Container maxWidth="lg">
          <Stack spacing={3}>
            <div>
              <Grid container spacing={3}>
                <Grid xs={12}>
                  <form>
                    <Card>
                      <CardHeader subheader="" title="Add Margin" />
                      <CardContent sx={{ pt: 3 }}>
                        <Box sx={{ m: -1.5 }}>
                          <Grid container spacing={3}>
                            <Grid xs={12} md={6}>
                              <TextField
                                fullWidth
                                label="Distance Range From(KM)"
                                placeholder="0"
                                name="distanceRangeFrom"
                                onChange={formik.handleChange}
                                error={formik.touched.distanceRangeFrom && Boolean(formik.errors.distanceRangeFrom)}
                                helperText={formik.touched.distanceRangeFrom && formik.errors.distanceRangeFrom}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                              />
                            </Grid>

                            <Grid xs={12} md={6}>
                              <TextField
                                fullWidth
                                label="Distance Range To(KM)"
                                placeholder="20"
                                name="distanceRangeTo"
                                onChange={formik.handleChange}
                                error={formik.touched.distanceRangeTo && Boolean(formik.errors.distanceRangeTo)}
                                helperText={formik.touched.distanceRangeTo && formik.errors.distanceRangeTo}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                              />
                            </Grid>
                            <Grid xs={12} md={6}>
                              <TextField
                                fullWidth
                                label="Truck Type"
                                name="truckType"
                                value={formik.values.truckType}
                                onChange={formik.handleChange}
                                select
                                SelectProps={{ native: true }}
                                InputLabelProps={{ shrink: true }}
                                error={formik.touched.truckType && Boolean(formik.errors.truckType)}
                              >
                                {TRUCK_TYPES.map((type) => (
                                  <option key={type} value={type}>
                                    {type}
                                  </option>
                                ))}
                              </TextField>
                            </Grid>
                            <Grid xs={12} md={6}>
                              <TextField
                                fullWidth
                                label="Per KM Rate"
                                placeholder="60"
                                type="per_km_price"
                                name="per_km_price"
                                onChange={formik.handleChange}
                                error={formik.touched.per_km_price && Boolean(formik.errors.per_km_price)}
                                helperText={formik.touched.per_km_price && formik.errors.per_km_price}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                              />
                            </Grid>
                            <Grid xs={12} md={6}>
                              <TextField
                                fullWidth
                                label="Fix cost (up to 100KM)"
                                placeholder="00"
                                type="fixed_cost"
                                name="fixed_cost"
                                onChange={formik.handleChange}
                                error={formik.touched.fixed_cost && Boolean(formik.errors.fixed_cost)}
                                helperText={formik.touched.fixed_cost && formik.errors.fixed_cost}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                              />
                            </Grid>
                          </Grid>
                        </Box>
                      </CardContent>
                      <Divider />
                      <div className="buttons">
                        <CardActions sx={{ justifyContent: 'flex-start', padding: '1rem 2rem 2rem 2rem' }}>
                          <Button variant="contained" onClick={formik.handleSubmit}>
                            Save details
                          </Button>
                        </CardActions>
                      </div>
                    </Card>
                  </form>
                </Grid>
              </Grid>
            </div>
          </Stack>
        </Container>
      </Box>
    </>
  );
};

export default AddMargin;
