import { createContext, useContext, useEffect, useState } from 'react';
import { createUserWithEmailAndPassword, signInWithEmailAndPassword, signOut, onAuthStateChanged } from 'firebase/auth';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { auth, firestoreDB } from '../config';

const userAuthContext = createContext();

export function UserAuthContextProvider({ children }) {
  const [user, setUser] = useState('');
  const [token, setToken] = useState('');
  const [userDetail, setUserDetail] = useState('');
  async function signUp(email, password) {
    const userData = await createUserWithEmailAndPassword(auth, email, password);
  }
  async function signIn(email, password) {
    const userData = await signInWithEmailAndPassword(auth, email, password);
    const userRef = query(collection(firestoreDB, 'users'), where('email', '==', email));
    const userSnapshot = await getDocs(userRef);
    const user = userSnapshot.docs.map((doc) => doc.data())[0] || {};
    const token = userData.user.stsTokenManager.accessToken;
    setToken(token);
    setUserDetail(user);
    return userData;
  }
  function logout() {
    setToken('');
    setUserDetail('');
    return signOut(auth);
  }

  useEffect(() => {
    const cleanup = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
      if (currentUser) {
        setToken(currentUser.stsTokenManager.accessToken);
      }
    });
    return () => {
      cleanup();
    };
  }, []);

  return (
    <userAuthContext.Provider value={{ user, token, userDetail, signUp, signIn, logout }}>
      {children}
    </userAuthContext.Provider>
  );
}

export function useUserAuth() {
  return useContext(userAuthContext);
}
