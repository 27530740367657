import React from 'react'

const BrickSizeTable = ({ type, data }) => {
    return (
        <>
          {data?.[type] ? (
            <div className="brick-type">
              <h3 className="with-height">{type}</h3>
              <div className="brick-sizes">
                
                <div className="brick-size" key="eightInch">
                  <p>8 Inch:</p>
                  <p className="price">
                    <span> &#8377; </span>
                    {data?.[type]?.eightInchInterlock || 0}{' '}
                  </p>
                  <p className="price">
                    <span> &#8377; </span>
                    {data?.[type]?.eightInchM || 0}{' '}
                  </p>
                </div>
                <div className="brick-size" key="sixInch">
                  <p>6 Inch:</p>
                  <p className="price">
                    <span>&#8377;</span> {data?.[type]?.sixInchInterlock || 0}{' '}
                  </p>
                  <p className="price">
                    <span>&#8377;</span> {data?.[type]?.sixInchM || 0}{' '}
                  </p>
                </div>
              </div>
            </div>
          ) : null}
        </>
      );
}

export default BrickSizeTable