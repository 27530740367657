import { Navigate, Routes, Route } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import SimpleLayout from './layouts/simple';
//
import LoginPage from './pages/LoginPage';
import Page404 from './pages/Page404';
import PincodesPage from './pages/Pincodes/PincodesPage';
import AddPincode from './pages/Pincodes/AddPincode';
import WheelsPage from './pages/Wheels/WheelsPage';
import AddWheel from './pages/Wheels/AddWheel';
import MarginsPage from './pages/Margins/MarginsPage';
import AddMargin from './pages/Margins/AddMargin';
import BrickPricesPage from './pages/BrickPrices/BrickPricesPage';
import AddBrickPrice from './pages/BrickPrices/AddBrickPrice';
import ViewBrickPrice from './pages/BrickPrices/ViewBrickPrice';
import ViewPinCode from './pages/Pincodes/ViewPinCode';
import ViewMargin from './pages/Margins/ViewMargin';
import ViewWheel from './pages/Wheels/ViewWheel';
import BrickLayoutPage from './pages/BricksLayout/BrickLayout';
import ViewBrickLayout from './pages/BricksLayout/ViewBrickLayout';
import AddBrickLayout from './pages/BricksLayout/AddBrickLayout';
import HomePage from './pages/HomePage';
import TransportPage from './pages/TransportPage';

// ----------------------------------------------------------------------

export default function Router() {
  return (
    <Routes>
       <Route index path="/" element={<HomePage />} />
       <Route path="/home" element={<HomePage />} />
       <Route path="/transport-cost" element={<TransportPage />} />
      <Route path="/dashboard" element={<DashboardLayout />}>
        <Route index element={<Navigate to="/dashboard/margins" />} />
        <Route path="pincodes">
          <Route index element={<PincodesPage />} />
          <Route path=":id" element={<ViewPinCode />} />
          <Route path="new" element={<AddPincode />} />
        </Route>
        <Route path="wheels">
          <Route index element={<WheelsPage />} />
          <Route path=":id" element={<ViewWheel />} />
          <Route path="new" element={<AddWheel />} />
        </Route>
        <Route path="margins">
          <Route index element={<MarginsPage />} />
          <Route path=":id" element={<ViewMargin />} />
          <Route path="new" element={<AddMargin />} />
        </Route>
        <Route path="brickPrices">
          <Route index element={<BrickPricesPage />} />
          <Route path=":id" element={<ViewBrickPrice />} />
          <Route path="new" element={<AddBrickPrice />} />
        </Route>
        <Route path="brickLayout">
          <Route index element={<BrickLayoutPage />} />
          <Route path=":id" element={<ViewBrickLayout />} />
          <Route path="new" element={<AddBrickLayout />} />
        </Route>
      </Route>
      <Route path="login" element={<LoginPage />} />
      <Route element={<SimpleLayout />}>
        {/* <Route index element={<Navigate to="/login" />} /> */}
        <Route path="404" element={<Page404 />} />
        <Route path="*" element={<Navigate to="/404" />} />
      </Route>
      <Route path="*" element={<Navigate to="/404" replace />} />
    </Routes>
  );
}
