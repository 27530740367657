import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { LoadingOverlay } from 'react-overlay-loader';
import 'react-overlay-loader/styles.css';
// routes
import Router from './routes';
// theme
import ThemeProvider from './theme';
// components
import { StyledChart } from './components/chart';
import ScrollToTop from './components/scroll-to-top';
import { UserAuthContextProvider } from './context/userAuthContext';

// ----------------------------------------------------------------------

export default function App() {
  return (
    <UserAuthContextProvider>
      <HelmetProvider>
        <BrowserRouter>
          <ThemeProvider>
            <ScrollToTop />
            <StyledChart />
            <LoadingOverlay>
              <Router />
            </LoadingOverlay>
            <ToastContainer />
          </ThemeProvider>
        </BrowserRouter>
      </HelmetProvider>
    </UserAuthContextProvider>
  );
}
