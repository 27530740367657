import { useState } from 'react';
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Container,
  Divider,
  Unstable_Grid2 as Grid,
  Stack,
  TextField,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import { toast } from 'react-toastify';
import { addDoc, collection } from 'firebase/firestore';
import { Loader } from 'react-overlay-loader';
import { firestoreDB } from '../../config';
import { BRICKS_SIZE, LAYING_TYPE, BRICKS_TYPES, STATUS } from '../../utils/constants';
import { AddBrickLayoutSchema } from '../../validations/BrickLayout.schema';

const AddBrickLayout = () => {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      brickSize: Object.keys(BRICKS_SIZE)[0] || '',
      layingType: Object.keys(LAYING_TYPE)[0] || '',
      perBrickCost: 0,
      status: 'active',
      brickType: Object.keys(BRICKS_TYPES)[0],
    },
    validationSchema: AddBrickLayoutSchema,
    onSubmit: async (values) => {
      try {
        setIsLoading(true);
        const wheelsRef = collection(firestoreDB, 'bricklayingcost');
        await addDoc(wheelsRef, {
          ...values,
          createdAt: new Date(),
          updatedAt: new Date(),
          isDeleted: false,
        });
        toast.success('Brick Laying added successfully');
        navigate('/dashboard/bricklayout');
      } catch (error) {
        console.log(error);
        toast.error('Something went wrong');
      } finally {
        setIsLoading(false);
      }
    },
  });

  return (
    <>
      <Loader fullPage loading={isLoading} />
      <Box
        component="main"
        sx={{
          flexGrow: 1,
        }}
      >
        <Container maxWidth="lg">
          <Stack spacing={3}>
            <div>
              <Grid container spacing={3}>
                <Grid xs={12}>
                  <form>
                    <Card>
                      <CardHeader subheader="" title="Add Brick Laying" />
                      <CardContent sx={{ pt: 3 }}>
                        <Box sx={{ m: -1.5 }}>
                          <Grid container spacing={3}>
                            <Grid xs={12} md={6}>
                              <TextField
                                fullWidth
                                label="Brick Size (In Inch)"
                                name="brickSize"
                                value={formik.values.brickSize}
                                onChange={formik.handleChange}
                                select
                                SelectProps={{ native: true }}
                                error={formik.touched.brickSize && Boolean(formik.errors.brickSize)}
                                InputLabelProps={{ shrink: true }}
                              >
                                {Object.keys(BRICKS_SIZE).map((type) => (
                                  <option key={type} value={type}>
                                    {BRICKS_SIZE[type]}
                                  </option>
                                ))}
                              </TextField>
                            </Grid>
                            <Grid xs={12} md={6}>
                              <TextField
                                fullWidth
                                label="Laying Type"
                                name="layingType"
                                value={formik.values.layingType}
                                onChange={formik.handleChange}
                                select
                                SelectProps={{ native: true }}
                                error={formik.touched.layingType && Boolean(formik.errors.layingType)}
                                InputLabelProps={{ shrink: true }}
                              >
                                {Object.keys(LAYING_TYPE).map((type) => (
                                  <option key={type} value={type}>
                                    {LAYING_TYPE[type]}
                                  </option>
                                ))}
                              </TextField>
                            </Grid>
                            <Grid xs={12} md={6}>
                              <TextField
                                fullWidth
                                label="Per Brick Cost"
                                placeholder="60"
                                type="per_brick_cost"
                                name="perBrickCost"
                                onChange={formik.handleChange}
                                error={formik.touched.perBrickCost && Boolean(formik.errors.perBrickCost)}
                                helperText={formik.touched.perBrickCost && formik.errors.perBrickCost}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                value={formik.values.perBrickCost}
                              />
                            </Grid>
                            <Grid xs={12} md={6}>
                            <TextField
                                fullWidth
                                label="Status"
                                name="status"
                                value={formik.values.status}
                                onChange={formik.handleChange}
                                select
                                SelectProps={{ native: true }}
                                error={formik.touched.status && Boolean(formik.errors.status)}
                              >
                                {Object.keys(STATUS).map((type) => (
                                  <option key={type} value={type}>
                                    {STATUS[type]}
                                  </option>
                                ))}
                              </TextField>
                            </Grid>
                            <Grid xs={12} md={6}>
                              <TextField
                                fullWidth
                                label="Brick Type"
                                name="brickType"
                                value={formik.values.brickType}
                                onChange={formik.handleChange}
                                select
                                SelectProps={{ native: true }}
                                error={formik.touched.brickType && Boolean(formik.errors.brickType)}
                              >
                                {Object.keys(BRICKS_TYPES).map((type) => (
                                  <option key={type} value={type}>
                                    {BRICKS_TYPES[type]}
                                  </option>
                                ))}
                              </TextField>
                            </Grid>
                          </Grid>
                        </Box>
                      </CardContent>
                      <Divider />
                      <div className="buttons">
                        <CardActions sx={{ justifyContent: 'flex-start', padding: '1rem 2rem 2rem 2rem' }}>
                          <Button variant="contained" onClick={formik.handleSubmit}>
                            Save details
                          </Button>
                        </CardActions>
                      </div>
                    </Card>
                  </form>
                </Grid>
              </Grid>
            </div>
          </Stack>
        </Container>
      </Box>
    </>
  );
};

export default AddBrickLayout;
