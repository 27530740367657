import React from 'react';

import logo1 from '../assets/logo-01.jpg';
import logo2 from '../assets/logo-02.jpg';
import logo3 from '../assets/logo-03.jpg';
import checkIcon from '../assets/icon-check.jpg';

const HtmlToPdf = ({ resultRef, resultObj, brickLaying }) => {
  const layingDataObject = brickLaying.reduce((result, item) => {
    const { layingType, status, perBrickCost, brickSize } = item;
  
    if (!result[layingType]) {
      result[layingType] = {
        eightInchPrice: 0,
        sixInchPrice: 0,
        eightInchStatus: "",
        sixInchStatus: "",
      };
    }
  
    result[layingType][`${brickSize}InchPrice`] = Number(perBrickCost);
    result[layingType][`${brickSize}InchStatus`] = status;
  
    return result;
  }, {});
  return (
    <div
      ref={resultRef}
      style={{
        padding: 0,
        margin: 0,
      }}
    >
      {/* 8Inch - Dolfix Interlock Pricing Plan Start */}
      <table
        width={1300}
        style={{
          background: '#f4f6fc',
          paddingTop: 16,
          paddingRight: 16,
          paddingLeft: 16,
        }}
        cellPadding={0}
        cellSpacing={0}
        border={0}
        align="center"
      >
        <tbody>
          <tr>
            <td style={{ position: 'relative', width: '150px' }}>
              <img
                src={logo1}
                width={115}
                style={{ position: 'absolute', top: 0, left: 0, width: '115px', height: '115px' }}
                alt=""
              />
            </td>
            <td>
              <table width={1000} cellPadding={0} cellSpacing={0} border={0} align="center">
                <tbody>
                  <tr>
                    <td>
                      <h1
                        style={{
                          fontSize: 38,
                          textAlign: 'center',
                          fontWeight: 800,
                          fontFamily: 'Arial, Helvetica, sans-serif',
                          margin: 0,
                          marginBottom: 10,
                        }}
                      >
                        8Inch - Dolfix Interlock Pricing Plan
                      </h1>
                      <h2
                        style={{
                          fontSize: 25,
                          textAlign: 'center',
                          fontWeight: 400,
                          fontFamily: 'Arial, Helvetica, sans-serif',
                          margin: 0,
                          marginBottom: 10,
                        }}
                      >
                        Join <strong>5,000</strong> happy customers
                      </h2>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <table width="100%" cellPadding={0} cellSpacing={0} border={0} align="center">
                        <tbody>
                          <tr>
                            <td
                              colSpan={7}
                              style={{
                                background: '#ffffff',
                                borderTopLeftRadius: 15,
                                borderTopRightRadius: 15,
                              }}
                            >
                              &nbsp;
                            </td>
                          </tr>
                          <tr>
                            <td style={{ background: '#ffffff' }} width={40} />
                            <td style={{ background: '#ffffff' }} width={280} valign="top">
                              <table width="100%" cellPadding={0} cellSpacing={0} border={0}>
                                <tbody>
                                  <tr>
                                    <td style={{textAlign:"left"}}>
                                      <h3
                                        style={{
                                          fontSize: 32,
                                          paddingLeft: 0,
                                          fontWeight: 800,
                                          fontFamily: 'Arial, Helvetica, sans-serif',
                                          color: '#1f1645',
                                          margin: 0,
                                          
                                        }}
                                      >
                                        STANDARD
                                      </h3>
                                      <h4
                                        style={{
                                          fontSize: 24,
                                          fontWeight: 400,
                                          fontFamily: 'Calibri, Helvetica, sans-serif',
                                          color: '#667581',
                                          margin: 0,
                                          marginLeft: 0,
                                          display: 'block',
                                          position: 'relative',
                                          
                                        }}
                                      >
                                        
                                        <span style={{position:'relative'}}>
                                        <div
                                          style={{
                                            position: 'absolute',
                                            top: '25px',
                                            height: 5,
                                            width: '80px',
                                            left:'50%',
                                            marginLeft:'-40px',
                                            opacity: '.7',
                                            background: '#dedfdf',
                                          }}
                                        />
                                        <span style={{ fontSize: '90%' }}>₹</span> 96.00
                                        </span>
                                      </h4>
                                      <h4
                                        style={{
                                          fontSize: 32,
                                          paddingLeft: 0,
                                          fontWeight: 400,
                                          fontFamily: 'Calibri, Helvetica, sans-serif',
                                          color: '#ff0202',
                                          margin: 0,
                                          position: 'relative',
                                          
                                        }}
                                      >
                                        <span
                                          style={{
                                            fontSize: '90%',
                                            color: '#667581',
                                          }}
                                        >
                                          ₹
                                        </span>
                                        {resultObj?.Standard?.eightInchInterlock}
                                      </h4>
                                      <h4
                                        style={{
                                          fontSize: 24,
                                          paddingLeft: 0,
                                          fontWeight: 400,
                                          fontFamily: 'Calibri, Helvetica, sans-serif',
                                          color: '#000000',
                                          margin: 0,
                                          marginBottom: 20,
                                          position: 'relative',
                                          
                                        }}
                                      >
                                        /BRICK
                                      </h4>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <p
                                        style={{
                                          fontSize: 16,
                                          fontWeight: 600,
                                          fontFamily: 'Calibri, Helvetica, sans-serif',
                                          color: '#000000',
                                          margin: 0,
                                          marginBottom: 5,
                                        }}
                                      >
                                        Services Included
                                      </p>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <table
                                        cellPadding={0}
                                        cellSpacing={0}
                                        border={0}
                                        width="100%"
                                        style={{
                                          fontSize: 16,
                                          fontWeight: 400,
                                          fontFamily: 'Calibri, Helvetica, sans-serif',
                                          color: '#000000',
                                          margin: 0,
                                          marginBottom: 5,
                                          lineHeight: '16px',
                                        }}
                                      >
                                        <tbody>
                                          <tr>
                                            <td width={24} valign="middle">
                                              <img
                                                src={checkIcon}
                                                style={{ verticalAlign: 'baseline' }}
                                                width={16}
                                                alt=""
                                              />
                                            </td>
                                            <td>
                                              <p>10L X 5H X 8W (Inch Dimensions)</p>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td width={24} valign="middle">
                                              <img
                                                src={checkIcon}
                                                style={{ verticalAlign: 'baseline' }}
                                                width={16}
                                                alt=""
                                              />
                                            </td>
                                            <td>
                                              <p>Cooler Interior</p>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td width={24} valign="middle">
                                              <img
                                                src={checkIcon}
                                                style={{ verticalAlign: 'baseline' }}
                                                width={16}
                                                alt=""
                                              />
                                            </td>
                                            <td>
                                              <p>Cost Savings by 40%</p>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td width={24} valign="middle">
                                              <img
                                                src={checkIcon}
                                                style={{ verticalAlign: 'baseline' }}
                                                width={16}
                                                alt=""
                                              />
                                            </td>
                                            <td>
                                              <p>Budget Home</p>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td width={24} valign="middle">
                                              <img
                                                src={checkIcon}
                                                style={{ verticalAlign: 'baseline' }}
                                                width={16}
                                                alt=""
                                              />
                                            </td>
                                            <td>
                                              <p>Laterite Eco-Friendly Bricks</p>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td width={24} valign="middle">
                                              <img
                                                src={checkIcon}
                                                style={{ verticalAlign: 'baseline' }}
                                                width={16}
                                                alt=""
                                              />
                                            </td>
                                            <td>
                                              <p>Insect Control</p>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td width={24} valign="middle">
                                              <img
                                                src={checkIcon}
                                                style={{ verticalAlign: 'baseline' }}
                                                width={16}
                                                alt=""
                                              />
                                            </td>
                                            <td>
                                              <p>No oxygen gaps</p>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td width={24} valign="middle">
                                              <img
                                                src={checkIcon}
                                                style={{ verticalAlign: 'baseline' }}
                                                width={16}
                                                alt=""
                                              />
                                            </td>
                                            <td>
                                              <p>Less Labour</p>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td width={24} valign="middle">
                                              <img
                                                src={checkIcon}
                                                style={{ verticalAlign: 'baseline' }}
                                                width={16}
                                                alt=""
                                              />
                                            </td>
                                            <td>
                                              <p>Time Savings</p>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td width={24} valign="middle">
                                              <img
                                                src={checkIcon}
                                                style={{ verticalAlign: 'baseline' }}
                                                width={16}
                                                alt=""
                                              />
                                            </td>
                                            <td>
                                              <p>Strong &amp; Durable</p>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td width={24} valign="middle">
                                              <img
                                                src={checkIcon}
                                                style={{ verticalAlign: 'baseline' }}
                                                width={16}
                                                alt=""
                                              />
                                            </td>
                                            <td>
                                              <p>Lowest Maintenance</p>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td width={24} valign="middle">
                                              <img
                                                src={checkIcon}
                                                style={{ verticalAlign: 'baseline' }}
                                                width={16}
                                                alt=""
                                              />
                                            </td>
                                            <td>
                                              <p>Salvaged without Damage &amp; Unbreakable</p>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                            <td style={{ background: '#ffffff' }} width={40} />
                            <td style={{ background: '#ffffff' }} width={280} valign="top">
                              <table width="100%" cellPadding={0} cellSpacing={0} border={0}>
                                <tbody>
                                  <tr>
                                    <td style={{textAlign:"left"}}>
                                      <h3
                                        style={{
                                          fontSize: 32,
                                          paddingLeft: 0,
                                          fontWeight: 800,
                                          fontFamily: 'Arial, Helvetica, sans-serif',
                                          color: '#1f1645',
                                          margin: 0,
                                    
                                        }}
                                      >
                                        SUPER
                                      </h3>
                                      <h4
                                        style={{
                                          fontSize: 24,
                                          fontWeight: 400,
                                          fontFamily: 'Calibri, Helvetica, sans-serif',
                                          color: '#667581',
                                          margin: 0,
                                          marginLeft: 0,
                                          display: 'block',
                                          position: 'relative',
                                          
                                        }}
                                      >
                                        <span style={{position:'relative'}}>
                                        <div
                                          style={{
                                            position: 'absolute',
                                            top: '25px',
                                            height: 5,
                                            width: '80px',
                                            left:'50%',
                                            marginLeft:'-40px',
                                            opacity: '.7',
                                            background: '#dedfdf',
                                          }}
                                        />
                                        <span style={{ fontSize: '90%' }}>₹</span> 120.00
                                        </span>
                                        
                                      </h4>
                                      <h4
                                        style={{
                                          fontSize: 32,
                                          paddingLeft: 0,
                                          fontWeight: 400,
                                          fontFamily: 'Calibri, Helvetica, sans-serif',
                                          color: '#ff0202',
                                          margin: 0,
                                          position: 'relative',
                                          
                                        }}
                                      >
                                        <span
                                          style={{
                                            fontSize: '90%',
                                            color: '#667581',
                                          }}
                                        >
                                          ₹
                                        </span>{' '}
                                        {resultObj?.Super?.eightInchInterlock}
                                      </h4>
                                      <h4
                                        style={{
                                          fontSize: 24,
                                          paddingLeft: 0,
                                          fontWeight: 400,
                                          fontFamily: 'Calibri, Helvetica, sans-serif',
                                          color: '#000000',
                                          margin: 0,
                                          marginBottom: 20,
                                          position: 'relative',
                                          
                                        }}
                                      >
                                        /BRICK
                                      </h4>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <p
                                        style={{
                                          fontSize: 16,
                                          fontWeight: 600,
                                          fontFamily: 'Calibri, Helvetica, sans-serif',
                                          color: '#000000',
                                          margin: 0,
                                          marginBottom: 5,
                                        }}
                                      >
                                        Services Included
                                      </p>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <table
                                        cellPadding={0}
                                        cellSpacing={0}
                                        border={0}
                                        width="100%"
                                        style={{
                                          fontSize: 16,
                                          fontWeight: 400,
                                          fontFamily: 'Calibri, Helvetica, sans-serif',
                                          color: '#000000',
                                          margin: 0,
                                          marginBottom: 5,
                                          lineHeight: '16px',
                                        }}
                                      >
                                        <tbody>
                                          <tr>
                                            <td width={24} valign="middle">
                                              <img
                                                src={checkIcon}
                                                style={{ verticalAlign: 'baseline' }}
                                                width={16}
                                                alt=""
                                              />
                                            </td>
                                            <td valign="top">
                                              <p>All Standard Brick Features Included</p>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td width={24} valign="middle">
                                              <img
                                                src={checkIcon}
                                                style={{ verticalAlign: 'baseline' }}
                                                width={16}
                                                alt=""
                                              />
                                            </td>
                                            <td>
                                              <p>Earthquake Resistant</p>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td width={24} valign="middle">
                                              <img
                                                src={checkIcon}
                                                style={{ verticalAlign: 'baseline' }}
                                                width={16}
                                                alt=""
                                              />
                                            </td>
                                            <td>
                                              <p>Dedicated Engineer at Brick Manufacturing</p>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td width={24} valign="middle">
                                              <img
                                                src={checkIcon}
                                                style={{ verticalAlign: 'baseline' }}
                                                width={16}
                                                alt=""
                                              />
                                            </td>
                                            <td>
                                              <p>Higher Compressive Strength</p>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td width={24} valign="middle">
                                              <img
                                                src={checkIcon}
                                                style={{ verticalAlign: 'baseline' }}
                                                width={16}
                                                alt=""
                                              />
                                            </td>
                                            <td>
                                              <p>Lowest Water Absorption</p>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td width={24} valign="middle">
                                              <img
                                                src={checkIcon}
                                                style={{ verticalAlign: 'baseline' }}
                                                width={16}
                                                alt=""
                                              />
                                            </td>
                                            <td>
                                              <p>Priority Shipment</p>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td width={24} valign="middle">
                                              <img
                                                src={checkIcon}
                                                style={{ verticalAlign: 'baseline' }}
                                                width={16}
                                                alt=""
                                              />
                                            </td>
                                            <td>
                                              <p>Shipment Tracking</p>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                            <td style={{ background: '#ffffff' }} width={40} />
                            <td style={{ background: '#ffffff' }} width={280} valign="top">
                              <table width="100%" cellPadding={0} cellSpacing={0} border={0}>
                                <tbody>
                                  <tr>
                                    <td style={{textAlign:"left"}}>
                                      <h3
                                        style={{
                                          fontSize: 32,
                                          paddingLeft: 0,
                                          fontWeight: 800,
                                          fontFamily: 'Arial, Helvetica, sans-serif',
                                          color: '#1f1645',
                                          margin: 0,
                                          
                                        }}
                                      >
                                        PRIME
                                      </h3>
                                      <h4
                                        style={{
                                          fontSize: 24,
                                          fontWeight: 400,
                                          fontFamily: 'Calibri, Helvetica, sans-serif',
                                          color: '#667581',
                                          margin: 0,
                                          marginLeft: 0,
                                          display: 'block',
                                          position: 'relative',
                                          
                                        }}
                                      >
                                        <span style={{position:'relative'}}>
                                        <div
                                          style={{
                                            position: 'absolute',
                                            top: '25px',
                                            height: 5,
                                            width: '80px',
                                            left:'50%',
                                            marginLeft:'-40px',
                                            opacity: '.7',
                                            background: '#dedfdf',
                                          }}
                                        />
                                        <span style={{ fontSize: '90%' }}>₹</span> 160.00
                                        </span>
                                        
                                      </h4>
                                      <h4
                                        style={{
                                          fontSize: 32,
                                          paddingLeft: 0,
                                          fontWeight: 400,
                                          fontFamily: 'Calibri, Helvetica, sans-serif',
                                          color: '#ff0202',
                                          margin: 0,
                                          position: 'relative',
                                          
                                        }}
                                      >
                                        <span
                                          style={{
                                            fontSize: '90%',
                                            color: '#667581',
                                          }}
                                        >
                                          ₹
                                        </span>{' '}
                                        {resultObj?.Prime?.eightInchInterlock}
                                      </h4>
                                      <h4
                                        style={{
                                          fontSize: 24,
                                          paddingLeft: 0,
                                          fontWeight: 400,
                                          fontFamily: 'Calibri, Helvetica, sans-serif',
                                          color: '#000000',
                                          margin: 0,
                                          marginBottom: 20,
                                          position: 'relative',
                                          
                                        }}
                                      >
                                        /BRICK
                                      </h4>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <p
                                        style={{
                                          fontSize: 16,
                                          fontWeight: 600,
                                          fontFamily: 'Calibri, Helvetica, sans-serif',
                                          color: '#000000',
                                          margin: 0,
                                          marginBottom: 5,
                                        }}
                                      >
                                        Services Included
                                      </p>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <table
                                        cellPadding={0}
                                        cellSpacing={0}
                                        border={0}
                                        width="100%"
                                        style={{
                                          fontSize: 16,
                                          fontWeight: 400,
                                          fontFamily: 'Calibri, Helvetica, sans-serif',
                                          color: '#000000',
                                          margin: 0,
                                          marginBottom: 5,
                                          lineHeight: '16px',
                                        }}
                                      >
                                        <tbody>
                                          <tr>
                                            <td width={24} valign="middle">
                                              <img
                                                src={checkIcon}
                                                style={{ verticalAlign: 'baseline' }}
                                                width={16}
                                                alt=""
                                              />
                                            </td>
                                            <td>
                                              <p>All Features Included</p>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td width={24} valign="middle">
                                              <img
                                                src={checkIcon}
                                                style={{ verticalAlign: 'baseline' }}
                                                width={16}
                                                alt=""
                                              />
                                            </td>
                                            <td>
                                              <p>Status Symbol in Society</p>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td width={24} valign="middle">
                                              <img
                                                src={checkIcon}
                                                style={{ verticalAlign: 'baseline' }}
                                                width={16}
                                                alt=""
                                              />
                                            </td>
                                            <td>
                                              <p>Unbreakable</p>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td width={24} valign="middle">
                                              <img
                                                src={checkIcon}
                                                style={{ verticalAlign: 'baseline' }}
                                                width={16}
                                                alt=""
                                              />
                                            </td>
                                            <td>
                                              <p>High compressive strength</p>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td width={24} valign="middle">
                                              <img
                                                src={checkIcon}
                                                style={{ verticalAlign: 'baseline' }}
                                                width={16}
                                                alt=""
                                              />
                                            </td>
                                            <td>
                                              <p>End to End Support</p>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td width={24} valign="middle">
                                              <img
                                                src={checkIcon}
                                                style={{ verticalAlign: 'baseline' }}
                                                width={16}
                                                alt=""
                                              />
                                            </td>
                                            <td>
                                              <p>Higher Finishing</p>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td width={24} valign="middle">
                                              <img
                                                src={checkIcon}
                                                style={{ verticalAlign: 'baseline' }}
                                                width={16}
                                                alt=""
                                              />
                                            </td>
                                            <td>
                                              <p>Sharper Edges</p>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td width={24} valign="middle">
                                              <img
                                                src={checkIcon}
                                                style={{ verticalAlign: 'baseline' }}
                                                width={16}
                                                alt=""
                                              />
                                            </td>
                                            <td>
                                              <p>Advanced Curing</p>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td width={24} valign="middle">
                                              <img
                                                src={checkIcon}
                                                style={{ verticalAlign: 'baseline' }}
                                                width={16}
                                                alt=""
                                              />
                                            </td>
                                            <td>
                                              <p>Priority After Service</p>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td width={24} valign="middle">
                                              <img
                                                src={checkIcon}
                                                style={{ verticalAlign: 'baseline' }}
                                                width={16}
                                                alt=""
                                              />
                                            </td>
                                            <td>
                                              <p>Dedicated Engineer in Worksite</p>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td width={24} valign="middle">
                                              <img
                                                src={checkIcon}
                                                style={{ verticalAlign: 'baseline' }}
                                                width={16}
                                                alt=""
                                              />
                                            </td>
                                            <td>
                                              <p>Bricks Shipment Insured</p>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                            <td style={{ background: '#ffffff' }} width={40} />
                          </tr>
                          <tr>
                            <td style={{ background: '#ffffff' }}>&nbsp;</td>
                            <td style={{ background: '#ffffff' }}>&nbsp;</td>
                            <td style={{ background: '#ffffff' }}>&nbsp;</td>
                            <td style={{ background: '#ffffff' }}>&nbsp;</td>
                            <td style={{ background: '#ffffff' }}>&nbsp;</td>
                            <td style={{ background: '#ffffff' }}>&nbsp;</td>
                            <td style={{ background: '#ffffff' }}>&nbsp;</td>
                          </tr>
                          <tr>
                            <td style={{ background: '#ffffff' }} />
                            <td style={{ background: '#dedfdf', height: 2 }} />
                            <td style={{ background: '#ffffff' }} />
                            <td style={{ background: '#dedfdf', height: 2 }} />
                            <td style={{ background: '#ffffff' }} />
                            <td style={{ background: '#dedfdf', height: 2 }} />
                            <td style={{ background: '#ffffff' }} />
                          </tr>
                          <tr>
                            <td style={{ background: '#ffffff' }}>&nbsp;</td>
                            <td style={{ background: '#ffffff' }}>&nbsp;</td>
                            <td style={{ background: '#ffffff' }}>&nbsp;</td>
                            <td style={{ background: '#ffffff' }}>&nbsp;</td>
                            <td style={{ background: '#ffffff' }}>&nbsp;</td>
                            <td style={{ background: '#ffffff' }}>&nbsp;</td>
                            <td style={{ background: '#ffffff' }}>&nbsp;</td>
                          </tr>
                          <tr>
                            <td style={{ background: '#ffffff' }} />
                            <td style={{ background: '#ffffff' }}>
                              <button
                                style={{
                                  background: '#bc1823',
                                  fontSize: 20,
                                  color: 'white',
                                  width: '100%',
                                  fontWeight: 400,
                                  padding: '0px  12px 18px',
                                  border: 'none',
                                  cursor: 'pointer',
                                }}
                              >
                                Contact Us
                              </button>
                            </td>
                            <td style={{ background: '#ffffff' }} />
                            <td style={{ background: '#ffffff' }}>
                              <button
                                style={{
                                  background: '#bc1823',
                                  fontSize: 20,
                                  color: 'white',
                                  width: '100%',
                                  fontWeight: 400,
                                  padding: '0px  12px 18px',
                                  border: 'none',
                                  cursor: 'pointer',
                                }}
                              >
                                Contact Us
                              </button>
                            </td>
                            <td style={{ background: '#ffffff' }} />
                            <td style={{ background: '#ffffff' }}>
                              <button
                                style={{
                                  background: '#bc1823',
                                  fontSize: 20,
                                  color: 'white',
                                  width: '100%',
                                  fontWeight: 400,
                                  padding: '0px  12px 18px',
                                  border: 'none',
                                  cursor: 'pointer',
                                }}
                              >
                                Contact Us
                              </button>
                            </td>
                            <td style={{ background: '#ffffff' }} />
                          </tr>
                          <tr>
                            <td
                              colSpan={7}
                              style={{
                                background: '#ffffff',
                                borderBottomLeftRadius: 15,
                                borderBottomRightRadius: 15,
                              }}
                            >
                              &nbsp;
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                  <tr>
                    <td align="center">
                      <img src="images/footer-bg.jpg" alt="" />
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
            <td style={{ position: 'relative', width: '150px' }}>
              <img
                src={logo2}
                width={114}
                style={{ position: 'absolute', top: 0, right: 125, width: '114px', height: '95px' }}
                alt=""
              />
              <img
                src={logo3}
                width={112}
                style={{ position: 'absolute', top: 0, right: 0, width: '112px', height: '94px' }}
                alt=""
              />
              
            </td>
          </tr>
        </tbody>
      </table>
      {/* 8Inch - Dolfix Interlock Pricing Plan End */}
      <table style={{width:"100%"}}>
        <tbody>
        <tr>
          <td style={{height:"60mm"}}>&nbsp;</td>
        </tr>
        </tbody>
      </table>
      
      {/* 6Inch - Dolfix Interlock Pricing Plan Start */}
      <table
        width={1300}
        style={{
          background: '#f4f6fc',
          paddingTop: 16,
          paddingRight: 16,
          paddingLeft: 16,
        }}
        cellPadding={0}
        cellSpacing={0}
        border={0}
        align="center"
      >
        <tbody>
          <tr>
            <td style={{ position: 'relative' }}>
              <img src="images/logo-01.jpg" width={115} style={{ position: 'absolute', top: 0, left: 0 }} alt="" />
            </td>
            <td>
              <table width={1000} cellPadding={0} cellSpacing={0} border={0} align="center">
                <tbody>
                  <tr>
                    <td>
                      <h1
                        style={{
                          fontSize: 38,
                          textAlign: 'center',
                          fontWeight: 800,
                          fontFamily: 'Arial, Helvetica, sans-serif',
                          margin: 0,
                          marginBottom: 10,
                        }}
                      >
                        6Inch - Dolfix Interlock Pricing Plan
                      </h1>
                      <h2
                        style={{
                          fontSize: 25,
                          textAlign: 'center',
                          fontWeight: 400,
                          fontFamily: 'Arial, Helvetica, sans-serif',
                          margin: 0,
                          marginBottom: 10,
                        }}
                      >
                        Join <strong>5,000</strong> happy customers
                      </h2>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <table width="100%" style={{}} cellPadding={0} cellSpacing={0} border={0} align="center">
                        <tbody>
                          <tr>
                            <td
                              colSpan={7}
                              style={{
                                background: '#ffffff',
                                borderTopLeftRadius: 15,
                                borderTopRightRadius: 15,
                              }}
                            >
                              &nbsp;
                            </td>
                          </tr>
                          <tr>
                            <td style={{ background: '#ffffff' }} width={40} />
                            <td style={{ background: '#ffffff' }} width={280} valign="top">
                              <table width="100%" cellPadding={0} cellSpacing={0} border={0}>
                                <tbody>
                                  <tr>
                                    <td style={{textAlign:"left"}}>
                                      <h3
                                        style={{
                                          fontSize: 32,
                                          paddingLeft: 0,
                                          fontWeight: 800,
                                          fontFamily: 'Arial, Helvetica, sans-serif',
                                          color: '#1f1645',
                                          margin: 0,
                                          
                                        }}
                                      >
                                        STANDARD
                                      </h3>
                                      <h4
                                        style={{
                                          fontSize: 24,
                                          fontWeight: 400,
                                          fontFamily: 'Calibri, Helvetica, sans-serif',
                                          color: '#667581',
                                          margin: 0,
                                          marginLeft: 0,
                                          display: 'block',
                                          position: 'relative',
                                          
                                        }}
                                      >
                                        <span style={{position:'relative'}}>
                                        <div
                                          style={{
                                            position: 'absolute',
                                            top: '25px',
                                            height: 5,
                                            width: '80px',
                                            left:'50%',
                                            marginLeft:'-40px',
                                            opacity: '.7',
                                            background: '#dedfdf',
                                          }}
                                        />
                                        <span style={{ fontSize: '90%' }}>₹</span> 96.00
                                        </span>
                                        
                                      </h4>
                                      <h4
                                        style={{
                                          fontSize: 32,
                                          paddingLeft: 0,
                                          fontWeight: 400,
                                          fontFamily: 'Calibri, Helvetica, sans-serif',
                                          color: '#ff0202',
                                          margin: 0,
                                          position: 'relative',
                                          
                                        }}
                                      >
                                        <span
                                          style={{
                                            fontSize: '90%',
                                            color: '#667581',
                                          }}
                                        >
                                          ₹
                                        </span>{' '}
                                        {resultObj?.Standard?.sixInchInterlock}
                                      </h4>
                                      <h4
                                        style={{
                                          fontSize: 24,
                                          paddingLeft: 0,
                                          fontWeight: 400,
                                          fontFamily: 'Calibri, Helvetica, sans-serif',
                                          color: '#000000',
                                          margin: 0,
                                          marginBottom: 20,
                                          position: 'relative',
                                          
                                        }}
                                      >
                                        /BRICK
                                      </h4>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <p
                                        style={{
                                          fontSize: 16,
                                          fontWeight: 600,
                                          fontFamily: 'Calibri, Helvetica, sans-serif',
                                          color: '#000000',
                                          margin: 0,
                                          marginBottom: 5,
                                        }}
                                      >
                                        Services Included
                                      </p>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <table
                                        cellPadding={0}
                                        cellSpacing={0}
                                        border={0}
                                        width="100%"
                                        style={{
                                          fontSize: 16,
                                          fontWeight: 400,
                                          fontFamily: 'Calibri, Helvetica, sans-serif',
                                          color: '#000000',
                                          margin: 0,
                                          lineHeight: '16px',
                                        }}
                                      >
                                        <tbody>
                                          <tr>
                                            <td width={24} valign="middle">
                                              <img
                                                src={checkIcon}
                                                width={16}
                                                alt=""
                                                style={{ verticalAlign: 'baseline' }}
                                              />
                                            </td>
                                            <td valign="top">
                                              <p>10L X 5H X 8W (Inch Dimensions)</p>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td width={24} valign="middle">
                                              <img
                                                src={checkIcon}
                                                style={{ verticalAlign: 'baseline' }}
                                                width={16}
                                                alt=""
                                              />
                                            </td>
                                            <td>
                                              <p>Cooler Interior</p>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td width={24} valign="middle">
                                              <img
                                                src={checkIcon}
                                                style={{ verticalAlign: 'baseline' }}
                                                width={16}
                                                alt=""
                                              />
                                            </td>
                                            <td>
                                              <p>Cost Savings by 40%</p>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td width={24} valign="middle">
                                              <img
                                                src={checkIcon}
                                                style={{ verticalAlign: 'baseline' }}
                                                width={16}
                                                alt=""
                                              />
                                            </td>
                                            <td>
                                              <p>Budget Home</p>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td width={24} valign="middle">
                                              <img
                                                src={checkIcon}
                                                style={{ verticalAlign: 'baseline' }}
                                                width={16}
                                                alt=""
                                              />
                                            </td>
                                            <td>
                                              <p>Laterite Eco-Friendly Bricks</p>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td width={24} valign="middle">
                                              <img
                                                src={checkIcon}
                                                style={{ verticalAlign: 'baseline' }}
                                                width={16}
                                                alt=""
                                              />
                                            </td>
                                            <td>
                                              <p>Insect Control</p>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td width={24} valign="middle">
                                              <img
                                                src={checkIcon}
                                                style={{ verticalAlign: 'baseline' }}
                                                width={16}
                                                alt=""
                                              />
                                            </td>
                                            <td>
                                              <p>No oxygen gaps</p>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td width={24} valign="middle">
                                              <img
                                                src={checkIcon}
                                                style={{ verticalAlign: 'baseline' }}
                                                width={16}
                                                alt=""
                                              />
                                            </td>
                                            <td>
                                              <p>Less Labour</p>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td width={24} valign="middle">
                                              <img
                                                src={checkIcon}
                                                style={{ verticalAlign: 'baseline' }}
                                                width={16}
                                                alt=""
                                              />
                                            </td>
                                            <td>
                                              <p>Time Savings</p>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td width={24} valign="middle">
                                              <img
                                                src={checkIcon}
                                                style={{ verticalAlign: 'baseline' }}
                                                width={16}
                                                alt=""
                                              />
                                            </td>
                                            <td>
                                              <p>Strong &amp; Durable</p>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td width={24} valign="middle">
                                              <img
                                                src={checkIcon}
                                                style={{ verticalAlign: 'baseline' }}
                                                width={16}
                                                alt=""
                                              />
                                            </td>
                                            <td>
                                              <p>Lowest Maintenance</p>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td width={24} valign="middle">
                                              <img
                                                src={checkIcon}
                                                style={{ verticalAlign: 'baseline' }}
                                                width={16}
                                                alt=""
                                              />
                                            </td>
                                            <td>
                                              <p>Salvaged without Damage &amp; Unbreakable</p>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                            <td style={{ background: '#ffffff' }} width={40} />
                            <td style={{ background: '#ffffff' }} width={280} valign="top">
                              <table width="100%" cellPadding={0} cellSpacing={0} border={0}>
                                <tbody>
                                  <tr>
                                    <td style={{textAlign:"left"}}>
                                      <h3
                                        style={{
                                          fontSize: 32,
                                          paddingLeft: 0,
                                          fontWeight: 800,
                                          fontFamily: 'Arial, Helvetica, sans-serif',
                                          color: '#1f1645',
                                          margin: 0,
                                          
                                        }}
                                      >
                                        SUPER
                                      </h3>
                                      <h4
                                        style={{
                                          fontSize: 24,
                                          fontWeight: 400,
                                          fontFamily: 'Calibri, Helvetica, sans-serif',
                                          color: '#667581',
                                          margin: 0,
                                          marginLeft: 0,
                                          display: 'block',
                                          position: 'relative',
                                          
                                        }}
                                      >
                                        <span style={{position:'relative'}}>
                                        <div
                                          style={{
                                            position: 'absolute',
                                            top: '25px',
                                            height: 5,
                                            width: '80px',
                                            left:'50%',
                                            marginLeft:'-40px',
                                            opacity: '.7',
                                            background: '#dedfdf',
                                          }}
                                        />
                                        <span style={{ fontSize: '90%' }}>₹</span> 120.00
                                        </span>
                                         
                                      </h4>
                                      <h4
                                        style={{
                                          fontSize: 32,
                                          paddingLeft: 0,
                                          fontWeight: 400,
                                          fontFamily: 'Calibri, Helvetica, sans-serif',
                                          color: '#ff0202',
                                          margin: 0,
                                          position: 'relative',
                                          
                                        }}
                                      >
                                        <span
                                          style={{
                                            fontSize: '90%',
                                            color: '#667581',
                                          }}
                                        >
                                          ₹
                                        </span>{' '}
                                        {resultObj?.Super?.sixInchInterlock}
                                      </h4>
                                      <h4
                                        style={{
                                          fontSize: 24,
                                          paddingLeft: 0,
                                          fontWeight: 400,
                                          fontFamily: 'Calibri, Helvetica, sans-serif',
                                          color: '#000000',
                                          margin: 0,
                                          marginBottom: 20,
                                          position: 'relative',
                                          
                                        }}
                                      >
                                        /BRICK
                                      </h4>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <p
                                        style={{
                                          fontSize: 16,
                                          fontWeight: 600,
                                          fontFamily: 'Calibri, Helvetica, sans-serif',
                                          color: '#000000',
                                          margin: 0,
                                          marginBottom: 5,
                                        }}
                                      >
                                        Services Included
                                      </p>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <table
                                        cellPadding={0}
                                        cellSpacing={0}
                                        border={0}
                                        width="100%"
                                        style={{
                                          fontSize: 16,
                                          fontWeight: 400,
                                          fontFamily: 'Calibri, Helvetica, sans-serif',
                                          color: '#000000',
                                          margin: 0,
                                          marginBottom: 5,
                                          lineHeight: '16px',
                                        }}
                                      >
                                        <tbody>
                                          <tr>
                                            <td width={24} valign="middle">
                                              <img
                                                src={checkIcon}
                                                style={{ verticalAlign: 'baseline' }}
                                                width={16}
                                                alt=""
                                              />
                                            </td>
                                            <td>
                                              <p>All Standard Brick Features Included</p>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td width={24} valign="middle">
                                              <img
                                                src={checkIcon}
                                                style={{ verticalAlign: 'baseline' }}
                                                width={16}
                                                alt=""
                                              />
                                            </td>
                                            <td>
                                              <p>Earthquake Resistant</p>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td width={24} valign="middle">
                                              <img
                                                src={checkIcon}
                                                style={{ verticalAlign: 'baseline' }}
                                                width={16}
                                                alt=""
                                              />
                                            </td>
                                            <td>
                                              <p>Dedicated Engineer at Brick Manufacturing</p>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td width={24} valign="middle">
                                              <img
                                                src={checkIcon}
                                                style={{ verticalAlign: 'baseline' }}
                                                width={16}
                                                alt=""
                                              />
                                            </td>
                                            <td>
                                              <p>Higher Compressive Strength</p>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td width={24} valign="middle">
                                              <img
                                                src={checkIcon}
                                                style={{ verticalAlign: 'baseline' }}
                                                width={16}
                                                alt=""
                                              />
                                            </td>
                                            <td>
                                              <p>Lowest Water Absorption</p>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td width={24} valign="middle">
                                              <img
                                                src={checkIcon}
                                                style={{ verticalAlign: 'baseline' }}
                                                width={16}
                                                alt=""
                                              />
                                            </td>
                                            <td>
                                              <p>Priority Shipment</p>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td width={24} valign="middle">
                                              <img
                                                src={checkIcon}
                                                style={{ verticalAlign: 'baseline' }}
                                                width={16}
                                                alt=""
                                              />
                                            </td>
                                            <td>
                                              <p>Shipment Tracking</p>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                            <td style={{ background: '#ffffff' }} width={40} />
                            <td style={{ background: '#ffffff' }} width={280} valign="top">
                              <table width="100%" cellPadding={0} cellSpacing={0} border={0}>
                                <tbody>
                                  <tr>
                                    <td style={{textAlign:"left"}}>
                                      <h3
                                        style={{
                                          fontSize: 32,
                                          paddingLeft: 0,
                                          fontWeight: 800,
                                          fontFamily: 'Arial, Helvetica, sans-serif',
                                          color: '#1f1645',
                                          margin: 0,
                                          
                                        }}
                                      >
                                        PRIME
                                      </h3>
                                      <h4
                                        style={{
                                          fontSize: 24,
                                          fontWeight: 400,
                                          fontFamily: 'Calibri, Helvetica, sans-serif',
                                          color: '#667581',
                                          margin: 0,
                                          marginLeft: 0,
                                          display: 'block',
                                          position: 'relative',
                                          
                                        }}
                                      >
                                        <span style={{position:'relative'}}>
                                        <div
                                          style={{
                                            position: 'absolute',
                                            top: '25px',
                                            height: 5,
                                            width: '80px',
                                            left:'50%',
                                            marginLeft:'-40px',
                                            opacity: '.7',
                                            background: '#dedfdf',
                                          }}
                                        />
                                        <span style={{ fontSize: '90%' }}>₹</span> 160.00
                                        </span>
                                         
                                      </h4>
                                      <h4
                                        style={{
                                          fontSize: 32,
                                          paddingLeft: 0,
                                          fontWeight: 400,
                                          fontFamily: 'Calibri, Helvetica, sans-serif',
                                          color: '#ff0202',
                                          margin: 0,
                                          position: 'relative',
                                          
                                        }}
                                      >
                                        <span
                                          style={{
                                            fontSize: '90%',
                                            color: '#667581',
                                          }}
                                        >
                                          ₹
                                        </span>{' '}
                                        {resultObj?.Prime?.sixInchInterlock}
                                      </h4>
                                      <h4
                                        style={{
                                          fontSize: 24,
                                          paddingLeft: 0,
                                          fontWeight: 400,
                                          fontFamily: 'Calibri, Helvetica, sans-serif',
                                          color: '#000000',
                                          margin: 0,
                                          marginBottom: 20,
                                          position: 'relative',
                                          
                                        }}
                                      >
                                        /BRICK
                                      </h4>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <p
                                        style={{
                                          fontSize: 16,
                                          fontWeight: 600,
                                          fontFamily: 'Calibri, Helvetica, sans-serif',
                                          color: '#000000',
                                          margin: 0,
                                          marginBottom: 5,
                                        }}
                                      >
                                        Services Included
                                      </p>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <table
                                        cellPadding={0}
                                        cellSpacing={0}
                                        border={0}
                                        width="100%"
                                        style={{
                                          fontSize: 16,
                                          fontWeight: 400,
                                          fontFamily: 'Calibri, Helvetica, sans-serif',
                                          color: '#000000',
                                          margin: 0,
                                          marginBottom: 5,
                                          lineHeight: '16px',
                                        }}
                                      >
                                        <tbody>
                                          <tr>
                                            <td width={24} valign="middle">
                                              <img
                                                src={checkIcon}
                                                style={{ verticalAlign: 'baseline' }}
                                                width={16}
                                                alt=""
                                              />
                                            </td>
                                            <td>
                                              <p>All Features Included</p>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td width={24} valign="middle">
                                              <img
                                                src={checkIcon}
                                                style={{ verticalAlign: 'baseline' }}
                                                width={16}
                                                alt=""
                                              />
                                            </td>
                                            <td>
                                              <p>Status Symbol in Society</p>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td width={24} valign="middle">
                                              <img
                                                src={checkIcon}
                                                style={{ verticalAlign: 'baseline' }}
                                                width={16}
                                                alt=""
                                              />
                                            </td>
                                            <td>
                                              <p>Unbreakable</p>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td width={24} valign="middle">
                                              <img
                                                src={checkIcon}
                                                style={{ verticalAlign: 'baseline' }}
                                                width={16}
                                                alt=""
                                              />
                                            </td>
                                            <td>
                                              <p>High compressive strength</p>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td width={24} valign="middle">
                                              <img
                                                src={checkIcon}
                                                style={{ verticalAlign: 'baseline' }}
                                                width={16}
                                                alt=""
                                              />
                                            </td>
                                            <td>
                                              <p>End to End Support</p>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td width={24} valign="middle">
                                              <img
                                                src={checkIcon}
                                                style={{ verticalAlign: 'baseline' }}
                                                width={16}
                                                alt=""
                                              />
                                            </td>
                                            <td>
                                              <p>Higher Finishing</p>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td width={24} valign="middle">
                                              <img
                                                src={checkIcon}
                                                style={{ verticalAlign: 'baseline' }}
                                                width={16}
                                                alt=""
                                              />
                                            </td>
                                            <td>
                                              <p>Sharper Edges</p>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td width={24} valign="middle">
                                              <img
                                                src={checkIcon}
                                                style={{ verticalAlign: 'baseline' }}
                                                width={16}
                                                alt=""
                                              />
                                            </td>
                                            <td>
                                              <p>Advanced Curing</p>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td width={24} valign="middle">
                                              <img
                                                src={checkIcon}
                                                style={{ verticalAlign: 'baseline' }}
                                                width={16}
                                                alt=""
                                              />
                                            </td>
                                            <td>
                                              <p>Priority After Service</p>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td width={24} valign="middle">
                                              <img
                                                src={checkIcon}
                                                style={{ verticalAlign: 'baseline' }}
                                                width={16}
                                                alt=""
                                              />
                                            </td>
                                            <td>
                                              <p>Dedicated Engineer in Worksite</p>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td width={24} valign="middle">
                                              <img
                                                src={checkIcon}
                                                style={{ verticalAlign: 'baseline' }}
                                                width={16}
                                                alt=""
                                              />
                                            </td>
                                            <td>
                                              <p>Bricks Shipment Insured</p>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                            <td style={{ background: '#ffffff' }} width={40} />
                          </tr>
                          <tr>
                            <td style={{ background: '#ffffff' }}>&nbsp;</td>
                            <td style={{ background: '#ffffff' }}>&nbsp;</td>
                            <td style={{ background: '#ffffff' }}>&nbsp;</td>
                            <td style={{ background: '#ffffff' }}>&nbsp;</td>
                            <td style={{ background: '#ffffff' }}>&nbsp;</td>
                            <td style={{ background: '#ffffff' }}>&nbsp;</td>
                            <td style={{ background: '#ffffff' }}>&nbsp;</td>
                          </tr>
                          <tr>
                            <td style={{ background: '#ffffff' }} />
                            <td style={{ background: '#dedfdf', height: 2 }} />
                            <td style={{ background: '#ffffff' }} />
                            <td style={{ background: '#dedfdf', height: 2 }} />
                            <td style={{ background: '#ffffff' }} />
                            <td style={{ background: '#dedfdf', height: 2 }} />
                            <td style={{ background: '#ffffff' }} />
                          </tr>
                          <tr>
                            <td style={{ background: '#ffffff' }}>&nbsp;</td>
                            <td style={{ background: '#ffffff' }}>&nbsp;</td>
                            <td style={{ background: '#ffffff' }}>&nbsp;</td>
                            <td style={{ background: '#ffffff' }}>&nbsp;</td>
                            <td style={{ background: '#ffffff' }}>&nbsp;</td>
                            <td style={{ background: '#ffffff' }}>&nbsp;</td>
                            <td style={{ background: '#ffffff' }}>&nbsp;</td>
                          </tr>
                          <tr>
                            <td style={{ background: '#ffffff' }} />
                            <td style={{ background: '#ffffff' }}>
                              <button
                                style={{
                                  background: '#bc1823',
                                  fontSize: 20,
                                  color: 'white',
                                  width: '100%',
                                  fontWeight: 400,
                                  padding: '0px  12px 18px',
                                  border: 'none',
                                  cursor: 'pointer',
                                }}
                              >
                                Contact Us
                              </button>
                            </td>
                            <td style={{ background: '#ffffff' }} />
                            <td style={{ background: '#ffffff' }}>
                              <button
                                style={{
                                  background: '#bc1823',
                                  fontSize: 20,
                                  color: 'white',
                                  width: '100%',
                                  fontWeight: 400,
                                  padding: '0px  12px 18px',
                                  border: 'none',
                                  cursor: 'pointer',
                                }}
                              >
                                Contact Us
                              </button>
                            </td>
                            <td style={{ background: '#ffffff' }} />
                            <td style={{ background: '#ffffff' }}>
                              <button
                                style={{
                                  background: '#bc1823',
                                  fontSize: 20,
                                  color: 'white',
                                  width: '100%',
                                  fontWeight: 400,
                                  padding: '0px  12px 18px',
                                  border: 'none',
                                  cursor: 'pointer',
                                }}
                              >
                                Contact Us
                              </button>
                            </td>
                            <td style={{ background: '#ffffff' }} />
                          </tr>
                          <tr>
                            <td
                              colSpan={7}
                              style={{
                                background: '#ffffff',
                                borderBottomLeftRadius: 15,
                                borderBottomRightRadius: 15,
                              }}
                            >
                              &nbsp;
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                  <tr>
                    <td align="center">
                      <img src="images/footer-bg.jpg" alt="" />
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
            <td style={{ position: 'relative' }}>
              <img src="images/logo-02.jpg" width={114} style={{ position: 'absolute', top: 0, right: 0 }} alt="" />
              <img src="images/logo-03.jpg" width={112} style={{ position: 'absolute', top: 0, right: 125 }} alt="" />
            </td>
          </tr>
        </tbody>
      </table>
      {/* 6Inch - Dolfix Interlock Pricing Plan End */}
      <table style={{width:"100%"}}>
        <tbody>
        <tr>
          <td style={{height:"60mm"}}>&nbsp;</td>
        </tr>
        </tbody>
      </table>
      {/* 8Inch - Dolfix M-Brick Pricing Plan Start */}
      <table
        width={1300}
        style={{
          background: '#f4f6fc',

          paddingTop: 16,
          paddingRight: 16,
          paddingLeft: 16,
        }}
        cellPadding={0}
        cellSpacing={0}
        border={0}
        align="center"
      >
        <tbody>
          <tr>
            <td style={{ position: 'relative', width: '150px' }}>
              <img
                src={logo1}
                width={115}
                style={{ position: 'absolute', top: 0, left: 0, width: '115px', height: '115px' }}
                alt=""
              />
            </td>
            <td>
              <table width={1000} cellPadding={0} cellSpacing={0} border={0} align="center">
                <tbody>
                  <tr>
                    <td>
                      <h1
                        style={{
                          fontSize: 38,
                          textAlign: 'center',
                          fontWeight: 800,
                          fontFamily: 'Arial, Helvetica, sans-serif',
                          margin: 0,
                          marginBottom: 10,
                        }}
                      >
                        8Inch - Dolfix M-Brick Pricing Plan
                      </h1>
                      <h2
                        style={{
                          fontSize: 25,
                          textAlign: 'center',
                          fontWeight: 400,
                          fontFamily: 'Arial, Helvetica, sans-serif',
                          margin: 0,
                          marginBottom: 10,
                        }}
                      >
                        Join <strong>5,000</strong> happy customers
                      </h2>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <table width="100%" cellPadding={0} cellSpacing={0} border={0} align="center">
                        <tbody>
                          <tr>
                            <td
                              colSpan={7}
                              style={{
                                background: '#ffffff',
                                borderTopLeftRadius: 15,
                                borderTopRightRadius: 15,
                              }}
                            >
                              &nbsp;
                            </td>
                          </tr>
                          <tr>
                            <td style={{ background: '#ffffff' }} width={40} />
                            <td style={{ background: '#ffffff' }} width={280} valign="top">
                              <table width="100%" cellPadding={0} cellSpacing={0} border={0}>
                                <tbody>
                                  <tr>
                                    <td style={{textAlign:"left"}}>
                                      <h3
                                        style={{
                                          fontSize: 32,
                                          paddingLeft: 0,
                                          fontWeight: 800,
                                          fontFamily: 'Arial, Helvetica, sans-serif',
                                          color: '#1f1645',
                                          margin: 0,
                                          
                                        }}
                                      >
                                        STANDARD
                                      </h3>
                                      <h4
                                        style={{
                                          fontSize: 24,
                                          fontWeight: 400,
                                          fontFamily: 'Calibri, Helvetica, sans-serif',
                                          color: '#667581',
                                          margin: 0,
                                          marginLeft: 0,
                                          display: 'block',
                                          position: 'relative',
                                          
                                        }}
                                      >
                                        <span style={{position:'relative'}}>
                                        <div
                                          style={{
                                            position: 'absolute',
                                            top: '25px',
                                            height: 5,
                                            width: '80px',
                                            left:'50%',
                                            marginLeft:'-40px',
                                            opacity: '.7',
                                            background: '#dedfdf',
                                          }}
                                        />
                                        <span style={{ fontSize: '90%' }}>₹</span> 96.00
                                        </span>
                                         
                                         
                                      </h4>
                                      <h4
                                        style={{
                                          fontSize: 32,
                                          paddingLeft: 0,
                                          fontWeight: 400,
                                          fontFamily: 'Calibri, Helvetica, sans-serif',
                                          color: '#ff0202',
                                          margin: 0,
                                          position: 'relative',
                                          
                                        }}
                                      >
                                        <span
                                          style={{
                                            fontSize: '90%',
                                            color: '#667581',
                                          }}
                                        >
                                          ₹
                                        </span>{' '}
                                        {resultObj?.Standard?.eightInchM}
                                      </h4>
                                      <h4
                                        style={{
                                          fontSize: 24,
                                          paddingLeft: 0,
                                          fontWeight: 400,
                                          fontFamily: 'Calibri, Helvetica, sans-serif',
                                          color: '#000000',
                                          margin: 0,
                                          marginBottom: 20,
                                          position: 'relative',
                                          
                                        }}
                                      >
                                        /BRICK
                                      </h4>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <p
                                        style={{
                                          fontSize: 16,
                                          fontWeight: 600,
                                          fontFamily: 'Calibri, Helvetica, sans-serif',
                                          color: '#000000',
                                          margin: 0,
                                          marginBottom: 5,
                                        }}
                                      >
                                        Services Included
                                      </p>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <table
                                        cellPadding={0}
                                        cellSpacing={0}
                                        border={0}
                                        width="100%"
                                        style={{
                                          fontSize: 16,
                                          fontWeight: 400,
                                          fontFamily: 'Calibri, Helvetica, sans-serif',
                                          color: '#000000',
                                          margin: 0,
                                          marginBottom: 5,
                                          lineHeight: '16px',
                                        }}
                                      >
                                        <tbody>
                                          <tr>
                                            <td width={24} valign="middle">
                                              <img
                                                src={checkIcon}
                                                style={{ verticalAlign: 'baseline' }}
                                                width={16}
                                                alt=""
                                              />
                                            </td>
                                            <td>
                                              <p>10L X 5H X 8W (Inch Dimensions)</p>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td width={24} valign="middle">
                                              <img
                                                src={checkIcon}
                                                style={{ verticalAlign: 'baseline' }}
                                                width={16}
                                                alt=""
                                              />
                                            </td>
                                            <td>
                                              <p>Cooler Interior</p>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td width={24} valign="middle">
                                              <img
                                                src={checkIcon}
                                                style={{ verticalAlign: 'baseline' }}
                                                width={16}
                                                alt=""
                                              />
                                            </td>
                                            <td>
                                              <p>Cost Savings by 40%</p>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td width={24} valign="middle">
                                              <img
                                                src={checkIcon}
                                                style={{ verticalAlign: 'baseline' }}
                                                width={16}
                                                alt=""
                                              />
                                            </td>
                                            <td>
                                              <p>Budget Home</p>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td width={24} valign="middle">
                                              <img
                                                src={checkIcon}
                                                style={{ verticalAlign: 'baseline' }}
                                                width={16}
                                                alt=""
                                              />
                                            </td>
                                            <td>
                                              <p>Laterite Eco-Friendly Bricks</p>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td width={24} valign="middle">
                                              <img
                                                src={checkIcon}
                                                style={{ verticalAlign: 'baseline' }}
                                                width={16}
                                                alt=""
                                              />
                                            </td>
                                            <td>
                                              <p>Insect Control</p>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td width={24} valign="middle">
                                              <img
                                                src={checkIcon}
                                                style={{ verticalAlign: 'baseline' }}
                                                width={16}
                                                alt=""
                                              />
                                            </td>
                                            <td>
                                              <p>No oxygen gaps</p>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td width={24} valign="middle">
                                              <img
                                                src={checkIcon}
                                                style={{ verticalAlign: 'baseline' }}
                                                width={16}
                                                alt=""
                                              />
                                            </td>
                                            <td>
                                              <p>Less Labour</p>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td width={24} valign="middle">
                                              <img
                                                src={checkIcon}
                                                style={{ verticalAlign: 'baseline' }}
                                                width={16}
                                                alt=""
                                              />
                                            </td>
                                            <td>
                                              <p>Time Savings</p>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td width={24} valign="middle">
                                              <img
                                                src={checkIcon}
                                                style={{ verticalAlign: 'baseline' }}
                                                width={16}
                                                alt=""
                                              />
                                            </td>
                                            <td>
                                              <p>Strong &amp; Durable</p>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td width={24} valign="middle">
                                              <img
                                                src={checkIcon}
                                                style={{ verticalAlign: 'baseline' }}
                                                width={16}
                                                alt=""
                                              />
                                            </td>
                                            <td>
                                              <p>Lowest Maintenance</p>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td width={24} valign="middle">
                                              <img
                                                src={checkIcon}
                                                style={{ verticalAlign: 'baseline' }}
                                                width={16}
                                                alt=""
                                              />
                                            </td>
                                            <td>
                                              <p>Salvaged without Damage &amp; Unbreakable</p>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                            <td style={{ background: '#ffffff' }} width={40} />
                            <td style={{ background: '#ffffff' }} width={280} valign="top">
                              <table width="100%" cellPadding={0} cellSpacing={0} border={0}>
                                <tbody>
                                  <tr>
                                    <td style={{textAlign:"left"}}>
                                      <h3
                                        style={{
                                          fontSize: 32,
                                          paddingLeft: 0,
                                          fontWeight: 800,
                                          fontFamily: 'Arial, Helvetica, sans-serif',
                                          color: '#1f1645',
                                          margin: 0,
                                          
                                        }}
                                      >
                                        SUPER
                                      </h3>
                                      <h4
                                        style={{
                                          fontSize: 24,
                                          fontWeight: 400,
                                          fontFamily: 'Calibri, Helvetica, sans-serif',
                                          color: '#667581',
                                          margin: 0,
                                          marginLeft: 0,
                                          display: 'block',
                                          position: 'relative',
                                          
                                        }}
                                      >
                                        <span style={{position:'relative'}}>
                                        <div
                                          style={{
                                            position: 'absolute',
                                            top: '25px',
                                            height: 5,
                                            width: '80px',
                                            left:'50%',
                                            marginLeft:'-40px',
                                            opacity: '.7',
                                            background: '#dedfdf',
                                          }}
                                        />
                                        <span style={{ fontSize: '90%' }}>₹</span> 120.00
                                        </span>
                                         
                                      </h4>
                                      <h4
                                        style={{
                                          fontSize: 32,
                                          paddingLeft: 0,
                                          fontWeight: 400,
                                          fontFamily: 'Calibri, Helvetica, sans-serif',
                                          color: '#ff0202',
                                          margin: 0,
                                          position: 'relative',
                                          
                                        }}
                                      >
                                        <span
                                          style={{
                                            fontSize: '90%',
                                            color: '#667581',
                                          }}
                                        >
                                          ₹
                                        </span>{' '}
                                        {resultObj?.Super?.eightInchM}
                                      </h4>
                                      <h4
                                        style={{
                                          fontSize: 24,
                                          paddingLeft: 0,
                                          fontWeight: 400,
                                          fontFamily: 'Calibri, Helvetica, sans-serif',
                                          color: '#000000',
                                          margin: 0,
                                          marginBottom: 20,
                                          position: 'relative',
                                          
                                        }}
                                      >
                                        /BRICK
                                      </h4>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <p
                                        style={{
                                          fontSize: 16,
                                          fontWeight: 600,
                                          fontFamily: 'Calibri, Helvetica, sans-serif',
                                          color: '#000000',
                                          margin: 0,
                                          marginBottom: 5,
                                        }}
                                      >
                                        Services Included
                                      </p>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <table
                                        cellPadding={0}
                                        cellSpacing={0}
                                        border={0}
                                        width="100%"
                                        style={{
                                          fontSize: 16,
                                          fontWeight: 400,
                                          fontFamily: 'Calibri, Helvetica, sans-serif',
                                          color: '#000000',
                                          margin: 0,
                                          marginBottom: 5,
                                          lineHeight: '16px',
                                        }}
                                      >
                                        <tbody>
                                          <tr>
                                            <td width={24} valign="middle">
                                              <img
                                                src={checkIcon}
                                                style={{ verticalAlign: 'baseline' }}
                                                width={16}
                                                alt=""
                                              />
                                            </td>
                                            <td valign="top">
                                              <p>All Standard Brick Features Included</p>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td width={24} valign="middle">
                                              <img
                                                src={checkIcon}
                                                style={{ verticalAlign: 'baseline' }}
                                                width={16}
                                                alt=""
                                              />
                                            </td>
                                            <td>
                                              <p>Earthquake Resistant</p>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td width={24} valign="middle">
                                              <img
                                                src={checkIcon}
                                                style={{ verticalAlign: 'baseline' }}
                                                width={16}
                                                alt=""
                                              />
                                            </td>
                                            <td>
                                              <p>Dedicated Engineer at Brick Manufacturing</p>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td width={24} valign="middle">
                                              <img
                                                src={checkIcon}
                                                style={{ verticalAlign: 'baseline' }}
                                                width={16}
                                                alt=""
                                              />
                                            </td>
                                            <td>
                                              <p>Higher Compressive Strength</p>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td width={24} valign="middle">
                                              <img
                                                src={checkIcon}
                                                style={{ verticalAlign: 'baseline' }}
                                                width={16}
                                                alt=""
                                              />
                                            </td>
                                            <td>
                                              <p>Lowest Water Absorption</p>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td width={24} valign="middle">
                                              <img
                                                src={checkIcon}
                                                style={{ verticalAlign: 'baseline' }}
                                                width={16}
                                                alt=""
                                              />
                                            </td>
                                            <td>
                                              <p>Priority Shipment</p>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td width={24} valign="middle">
                                              <img
                                                src={checkIcon}
                                                style={{ verticalAlign: 'baseline' }}
                                                width={16}
                                                alt=""
                                              />
                                            </td>
                                            <td>
                                              <p>Shipment Tracking</p>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                            <td style={{ background: '#ffffff' }} width={40} />
                            <td style={{ background: '#ffffff' }} width={280} valign="top">
                              <table width="100%" cellPadding={0} cellSpacing={0} border={0}>
                                <tbody>
                                  <tr>
                                    <td style={{textAlign:"left"}}>
                                      <h3
                                        style={{
                                          fontSize: 32,
                                          paddingLeft: 0,
                                          fontWeight: 800,
                                          fontFamily: 'Arial, Helvetica, sans-serif',
                                          color: '#1f1645',
                                          margin: 0,
                                          
                                        }}
                                      >
                                        PRIME
                                      </h3>
                                      <h4
                                        style={{
                                          fontSize: 24,
                                          fontWeight: 400,
                                          fontFamily: 'Calibri, Helvetica, sans-serif',
                                          color: '#667581',
                                          margin: 0,
                                          marginLeft: 0,
                                          display: 'block',
                                          position: 'relative',
                                          
                                        }}
                                      >
                                        <span style={{position:'relative'}}>
                                        <div
                                          style={{
                                            position: 'absolute',
                                            top: '25px',
                                            height: 5,
                                            width: '80px',
                                            left:'50%',
                                            marginLeft:'-40px',
                                            opacity: '.7',
                                            background: '#dedfdf',
                                          }}
                                        />
                                        <span style={{ fontSize: '90%' }}>₹</span> 160.00
                                        </span>
                                         
                                      </h4>
                                      <h4
                                        style={{
                                          fontSize: 32,
                                          paddingLeft: 0,
                                          fontWeight: 400,
                                          fontFamily: 'Calibri, Helvetica, sans-serif',
                                          color: '#ff0202',
                                          margin: 0,
                                          position: 'relative',
                                          
                                        }}
                                      >
                                        <span
                                          style={{
                                            fontSize: '90%',
                                            color: '#667581',
                                          }}
                                        >
                                          ₹
                                        </span>{' '}
                                        {resultObj?.Prime?.eightInchM}
                                      </h4>
                                      <h4
                                        style={{
                                          fontSize: 24,
                                          paddingLeft: 0,
                                          fontWeight: 400,
                                          fontFamily: 'Calibri, Helvetica, sans-serif',
                                          color: '#000000',
                                          margin: 0,
                                          marginBottom: 20,
                                          position: 'relative',
                                          
                                        }}
                                      >
                                        /BRICK
                                      </h4>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <p
                                        style={{
                                          fontSize: 16,
                                          fontWeight: 600,
                                          fontFamily: 'Calibri, Helvetica, sans-serif',
                                          color: '#000000',
                                          margin: 0,
                                          marginBottom: 5,
                                        }}
                                      >
                                        Services Included
                                      </p>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <table
                                        cellPadding={0}
                                        cellSpacing={0}
                                        border={0}
                                        width="100%"
                                        style={{
                                          fontSize: 16,
                                          fontWeight: 400,
                                          fontFamily: 'Calibri, Helvetica, sans-serif',
                                          color: '#000000',
                                          margin: 0,
                                          marginBottom: 5,
                                          lineHeight: '16px',
                                        }}
                                      >
                                        <tbody>
                                          <tr>
                                            <td width={24} valign="middle">
                                              <img
                                                src={checkIcon}
                                                style={{ verticalAlign: 'baseline' }}
                                                width={16}
                                                alt=""
                                              />
                                            </td>
                                            <td>
                                              <p>All Features Included</p>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td width={24} valign="middle">
                                              <img
                                                src={checkIcon}
                                                style={{ verticalAlign: 'baseline' }}
                                                width={16}
                                                alt=""
                                              />
                                            </td>
                                            <td>
                                              <p>Status Symbol in Society</p>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td width={24} valign="middle">
                                              <img
                                                src={checkIcon}
                                                style={{ verticalAlign: 'baseline' }}
                                                width={16}
                                                alt=""
                                              />
                                            </td>
                                            <td>
                                              <p>Unbreakable</p>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td width={24} valign="middle">
                                              <img
                                                src={checkIcon}
                                                style={{ verticalAlign: 'baseline' }}
                                                width={16}
                                                alt=""
                                              />
                                            </td>
                                            <td>
                                              <p>High compressive strength</p>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td width={24} valign="middle">
                                              <img
                                                src={checkIcon}
                                                style={{ verticalAlign: 'baseline' }}
                                                width={16}
                                                alt=""
                                              />
                                            </td>
                                            <td>
                                              <p>End to End Support</p>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td width={24} valign="middle">
                                              <img
                                                src={checkIcon}
                                                style={{ verticalAlign: 'baseline' }}
                                                width={16}
                                                alt=""
                                              />
                                            </td>
                                            <td>
                                              <p>Higher Finishing</p>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td width={24} valign="middle">
                                              <img
                                                src={checkIcon}
                                                style={{ verticalAlign: 'baseline' }}
                                                width={16}
                                                alt=""
                                              />
                                            </td>
                                            <td>
                                              <p>Sharper Edges</p>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td width={24} valign="middle">
                                              <img
                                                src={checkIcon}
                                                style={{ verticalAlign: 'baseline' }}
                                                width={16}
                                                alt=""
                                              />
                                            </td>
                                            <td>
                                              <p>Advanced Curing</p>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td width={24} valign="middle">
                                              <img
                                                src={checkIcon}
                                                style={{ verticalAlign: 'baseline' }}
                                                width={16}
                                                alt=""
                                              />
                                            </td>
                                            <td>
                                              <p>Priority After Service</p>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td width={24} valign="middle">
                                              <img
                                                src={checkIcon}
                                                style={{ verticalAlign: 'baseline' }}
                                                width={16}
                                                alt=""
                                              />
                                            </td>
                                            <td>
                                              <p>Dedicated Engineer in Worksite</p>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td width={24} valign="middle">
                                              <img
                                                src={checkIcon}
                                                style={{ verticalAlign: 'baseline' }}
                                                width={16}
                                                alt=""
                                              />
                                            </td>
                                            <td>
                                              <p>Bricks Shipment Insured</p>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                            <td style={{ background: '#ffffff' }} width={40} />
                          </tr>
                          <tr>
                            <td style={{ background: '#ffffff' }}>&nbsp;</td>
                            <td style={{ background: '#ffffff' }}>&nbsp;</td>
                            <td style={{ background: '#ffffff' }}>&nbsp;</td>
                            <td style={{ background: '#ffffff' }}>&nbsp;</td>
                            <td style={{ background: '#ffffff' }}>&nbsp;</td>
                            <td style={{ background: '#ffffff' }}>&nbsp;</td>
                            <td style={{ background: '#ffffff' }}>&nbsp;</td>
                          </tr>
                          <tr>
                            <td style={{ background: '#ffffff' }} />
                            <td style={{ background: '#dedfdf', height: 2 }} />
                            <td style={{ background: '#ffffff' }} />
                            <td style={{ background: '#dedfdf', height: 2 }} />
                            <td style={{ background: '#ffffff' }} />
                            <td style={{ background: '#dedfdf', height: 2 }} />
                            <td style={{ background: '#ffffff' }} />
                          </tr>
                          <tr>
                            <td style={{ background: '#ffffff' }}>&nbsp;</td>
                            <td style={{ background: '#ffffff' }}>&nbsp;</td>
                            <td style={{ background: '#ffffff' }}>&nbsp;</td>
                            <td style={{ background: '#ffffff' }}>&nbsp;</td>
                            <td style={{ background: '#ffffff' }}>&nbsp;</td>
                            <td style={{ background: '#ffffff' }}>&nbsp;</td>
                            <td style={{ background: '#ffffff' }}>&nbsp;</td>
                          </tr>
                          <tr>
                            <td style={{ background: '#ffffff' }} />
                            <td style={{ background: '#ffffff' }}>
                              <button
                                style={{
                                  background: '#bc1823',
                                  fontSize: 20,
                                  color: 'white',
                                  width: '100%',
                                  fontWeight: 400,
                                  padding: '0px  12px 18px',
                                  border: 'none',
                                  cursor: 'pointer',
                                }}
                              >
                                Contact Us
                              </button>
                            </td>
                            <td style={{ background: '#ffffff' }} />
                            <td style={{ background: '#ffffff' }}>
                              <button
                                style={{
                                  background: '#bc1823',
                                  fontSize: 20,
                                  color: 'white',
                                  width: '100%',
                                  fontWeight: 400,
                                  padding: '0px  12px 18px',
                                  border: 'none',
                                  cursor: 'pointer',
                                }}
                              >
                                Contact Us
                              </button>
                            </td>
                            <td style={{ background: '#ffffff' }} />
                            <td style={{ background: '#ffffff' }}>
                              <button
                                style={{
                                  background: '#bc1823',
                                  fontSize: 20,
                                  color: 'white',
                                  width: '100%',
                                  fontWeight: 400,
                                  padding: '0px  12px 18px',
                                  border: 'none',
                                  cursor: 'pointer',
                                }}
                              >
                                Contact Us
                              </button>
                            </td>
                           
                            <td style={{ background: '#ffffff' }} />
                          </tr>
                          <tr>
                            <td
                              colSpan={7}
                              style={{
                                background: '#ffffff',
                                borderBottomLeftRadius: 15,
                                borderBottomRightRadius: 15,
                              }}
                            >
                              &nbsp;
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                  <tr>
                    <td align="center">
                      <img src="images/footer-bg.jpg" alt="" />
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
            <td style={{ position: 'relative', width: '150px' }}>
              
              <img
                src={logo2}
                width={114}
                style={{ position: 'absolute', top: 0, right: 125, width: '114px', height: '95px' }}
                alt=""
              />
              <img
                src={logo3}
                width={112}
                style={{ position: 'absolute', top: 0, right: 0,  width: '112px', height: '94px' }}
                alt=""
              />
              
            </td>
          </tr>
        </tbody>
      </table>
      {/* 8Inch - Dolfix M-Brick Pricing Plan End */}
      <table style={{width:"100%"}}>
        <tbody>
        <tr>
          <td style={{height:"20mm"}}>&nbsp;</td>
        </tr>
        </tbody>
      </table>
      {/* 8inch Dolfix Expert Brick Laying Start */}
      <table
        width={1300}
        style={{
          background: '#f4f6fc',
          paddingTop: 16,
          paddingRight: 16,
          paddingLeft: 16,
        }}
        cellPadding={0}
        cellSpacing={0}
        border={0}
        align="center"
      >
        <tbody>
          <tr>
            <td style={{ position: 'relative', width: '150px' }}>
              <img
                src={logo1}
                width={115}
                style={{ position: 'absolute', top: 0, left: 0, width: '115px', height: '115px' }}
                alt=""
              />
            </td>
            <td>
              <table width={680} cellPadding={0} cellSpacing={0} border={0} align="center">
                <tbody>
                  <tr>
                    <td>
                      <h1
                        style={{
                          fontSize: 38,
                          textAlign: 'center',
                          fontWeight: 800,
                          fontFamily: 'Arial, Helvetica, sans-serif',
                          margin: 0,
                          marginBottom: 10,
                        }}
                      >
                        8inch Dolfix Expert Brick Laying
                      </h1>
                      <h2
                        style={{
                          fontSize: 25,
                          textAlign: 'center',
                          fontWeight: 400,
                          fontFamily: 'Arial, Helvetica, sans-serif',
                          margin: 0,
                          marginBottom: 10,
                        }}
                      >
                        Join <strong>5,000</strong> happy customers
                      </h2>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <table width="100%" cellPadding={0} cellSpacing={0} border={0} align="center">
                        <tbody>
                          <tr>
                            <td
                              colSpan={5}
                              style={{
                                background: '#ffffff',
                                borderTopLeftRadius: 15,
                                borderTopRightRadius: 15,
                              }}
                            >
                              &nbsp;
                            </td>
                          </tr>
                          <tr>
                            {/* standard table start */}
                            <td style={{ background: '#ffffff' }} width={40}>&nbsp;</td>
                            <td style={{ background: '#ffffff' }} width={280} valign="top">
                              <table width="100%" cellPadding={0} cellSpacing={0} border={0}>
                                <tbody>
                                  <tr>
                                    <td style={{textAlign:"left"}}>
                                      <h3
                                        style={{
                                          fontSize: 32,
                                          paddingLeft: 0,
                                          fontWeight: 800,
                                          fontFamily: 'Arial, Helvetica, sans-serif',
                                          color: '#1f1645',
                                          margin: 0,
                                          
                                        }}
                                      >
                                        STANDARD
                                      </h3>
                                      <h4
                                        style={{
                                          fontSize: 24,
                                          fontWeight: 400,
                                          fontFamily: 'Calibri, Helvetica, sans-serif',
                                          color: '#667581',
                                          margin: 0,
                                          marginLeft: 0,
                                          display: 'block',
                                          position: 'relative',
                                          
                                        }}
                                      >
                                        <span style={{position:'relative'}}>
                                        <div
                                          style={{
                                            position: 'absolute',
                                            top: '25px',
                                            height: 5,
                                            width: '80px',
                                            left:'50%',
                                            marginLeft:'-40px',
                                            opacity: '.7',
                                            background: '#dedfdf',
                                          }}
                                        />
                                        <span style={{ fontSize: '90%' }}>₹</span> 96.00
                                        </span>
                                         
                                      </h4>
                                      <h4
                                        style={{
                                          fontSize: 32,
                                          paddingLeft: 0,
                                          fontWeight: 400,
                                          fontFamily: 'Calibri, Helvetica, sans-serif',
                                          color: '#ff0202',
                                          margin: 0,
                                          position: 'relative',
                                          
                                        }}
                                      >
                                        <span
                                          style={{
                                            fontSize: '90%',
                                            color: '#667581',
                                          }}
                                        >
                                          ₹
                                        </span>{' '}
                                        {layingDataObject?.Standard?.['8InchPrice']}
                                      </h4>
                                      <h4
                                        style={{
                                          fontSize: 24,
                                          paddingLeft: 0,
                                          fontWeight: 400,
                                          fontFamily: 'Calibri, Helvetica, sans-serif',
                                          color: '#000000',
                                          margin: 0,
                                          marginBottom: 20,
                                          position: 'relative',
                                          
                                        }}
                                      >
                                        /BRICK
                                      </h4>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <p
                                        style={{
                                          fontSize: 16,
                                          fontWeight: 600,
                                          fontFamily: 'Calibri, Helvetica, sans-serif',
                                          color: '#000000',
                                          margin: 0,
                                          marginBottom: 5,
                                        }}
                                      >
                                        Services Included
                                      </p>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <table
                                        cellPadding={0}
                                        cellSpacing={0}
                                        border={0}
                                        width="100%"
                                        style={{
                                          fontSize: 16,
                                          fontWeight: 400,
                                          fontFamily: 'Calibri, Helvetica, sans-serif',
                                          color: '#000000',
                                          margin: 0,
                                          marginBottom: 5,
                                          lineHeight: '16px',
                                        }}
                                      >
                                        <tbody>
                                          <tr>
                                            <td width={24} valign="middle">
                                              <img
                                                src={checkIcon}
                                                style={{ verticalAlign: 'baseline' }}
                                                width={16}
                                                alt=""
                                              />
                                            </td>
                                            <td>
                                              <p>Expert Mazans</p>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td width={24} valign="middle">
                                              <img
                                                src={checkIcon}
                                                style={{ verticalAlign: 'baseline' }}
                                                width={16}
                                                alt=""
                                              />
                                            </td>
                                            <td>
                                              <p>Expert Helpers</p>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td width={24} valign="middle">
                                              <img
                                                src={checkIcon}
                                                style={{ verticalAlign: 'baseline' }}
                                                width={16}
                                                alt=""
                                              />
                                            </td>
                                            <td>
                                              <p>Engineer Support</p>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td width={24} valign="middle">
                                              <img
                                                src={checkIcon}
                                                style={{ verticalAlign: 'baseline' }}
                                                width={16}
                                                alt=""
                                              />
                                            </td>
                                            <td>
                                              <p>Neat Work</p>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td width={24} valign="middle">
                                              <img
                                                src={checkIcon}
                                                style={{ verticalAlign: 'baseline' }}
                                                width={16}
                                                alt=""
                                              />
                                            </td>
                                            <td>
                                              <p>Excellent Finish</p>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td width={24} valign="middle">
                                              <img
                                                src={checkIcon}
                                                style={{ verticalAlign: 'baseline' }}
                                                width={16}
                                                alt=""
                                              />
                                            </td>
                                            <td>
                                              <p>Less Cement usage</p>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td width={24} valign="middle">
                                              <img
                                                src={checkIcon}
                                                style={{ verticalAlign: 'baseline' }}
                                                width={16}
                                                alt=""
                                              />
                                            </td>
                                            <td>
                                              <p>Less Wastage</p>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td width={24} valign="middle">
                                              <img
                                                src={checkIcon}
                                                style={{ verticalAlign: 'baseline' }}
                                                width={16}
                                                alt=""
                                              />
                                            </td>
                                            <td>
                                              <p>Fast Laying</p>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td width={24} valign="middle">
                                              <img
                                                src={checkIcon}
                                                style={{ verticalAlign: 'baseline' }}
                                                width={16}
                                                alt=""
                                              />
                                            </td>
                                            <td>
                                              <p>Strong & Durable</p>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td width={24} valign="middle">
                                              <img
                                                src={checkIcon}
                                                style={{ verticalAlign: 'baseline' }}
                                                width={16}
                                                alt=""
                                              />
                                            </td>
                                            <td>
                                              <p>After Laying Support</p>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td width={24} valign="middle">
                                              <img
                                                src={checkIcon}
                                                style={{ verticalAlign: 'baseline' }}
                                                width={16}
                                                alt=""
                                              />
                                            </td>
                                            <td>
                                              <p>Pointing work included</p>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                            <td style={{ background: '#ffffff' }} width={40}>&nbsp;</td>
                            {/* standard table end */}

                            {/* Prime table start */}
                            
                            <td style={{ background: '#ffffff' }} width={280} valign="top">
                              <table width="100%" cellPadding={0} cellSpacing={0} border={0}>
                                <tbody>
                                  <tr>
                                    <td style={{textAlign:"left"}}>
                                      <h3
                                        style={{
                                          fontSize: 32,
                                          paddingLeft: 0,
                                          fontWeight: 800,
                                          fontFamily: 'Arial, Helvetica, sans-serif',
                                          color: '#1f1645',
                                          margin: 0,
                                          
                                        }}
                                      >
                                        PRIME
                                      </h3>
                                      <h4
                                        style={{
                                          fontSize: 24,
                                          fontWeight: 400,
                                          fontFamily: 'Calibri, Helvetica, sans-serif',
                                          color: '#667581',
                                          margin: 0,
                                          marginLeft: 0,
                                          display: 'block',
                                          position: 'relative',
                                          
                                        }}
                                      >
                                        <span style={{position:'relative'}}>
                                        <div
                                          style={{
                                            position: 'absolute',
                                            top: '25px',
                                            height: 5,
                                            width: '80px',
                                            left:'50%',
                                            marginLeft:'-40px',
                                            opacity: '.7',
                                            background: '#dedfdf',
                                          }}
                                        />
                                        <span style={{ fontSize: '90%' }}>₹</span> 160.00
                                        </span>
                                         
                                         
                                      </h4>
                                      <h4
                                        style={{
                                          fontSize: 32,
                                          paddingLeft: 0,
                                          fontWeight: 400,
                                          fontFamily: 'Calibri, Helvetica, sans-serif',
                                          color: '#ff0202',
                                          margin: 0,
                                          position: 'relative',
                                          
                                        }}
                                      >
                                        <span
                                          style={{
                                            fontSize: '90%',
                                            color: '#667581',
                                          }}
                                        >
                                          ₹
                                        </span>{' '}
                                        {layingDataObject?.Premium?.['8InchPrice']}
                                      </h4>
                                      <h4
                                        style={{
                                          fontSize: 24,
                                          paddingLeft: 0,
                                          fontWeight: 400,
                                          fontFamily: 'Calibri, Helvetica, sans-serif',
                                          color: '#000000',
                                          margin: 0,
                                          marginBottom: 20,
                                          position: 'relative',
                                          
                                        }}
                                      >
                                        /BRICK
                                      </h4>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <p
                                        style={{
                                          fontSize: 16,
                                          fontWeight: 600,
                                          fontFamily: 'Calibri, Helvetica, sans-serif',
                                          color: '#000000',
                                          margin: 0,
                                          marginBottom: 5,
                                        }}
                                      >
                                        Services Included
                                      </p>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <table
                                        cellPadding={0}
                                        cellSpacing={0}
                                        border={0}
                                        width="100%"
                                        style={{
                                          fontSize: 16,
                                          fontWeight: 400,
                                          fontFamily: 'Calibri, Helvetica, sans-serif',
                                          color: '#000000',
                                          margin: 0,
                                          marginBottom: 5,
                                          lineHeight: '16px',
                                        }}
                                      >
                                        <tbody>
                                          <tr>
                                            <td width={24} valign="middle">
                                              <img
                                                src={checkIcon}
                                                style={{ verticalAlign: 'baseline' }}
                                                width={16}
                                                alt=""
                                              />
                                            </td>
                                            <td>
                                              <p>All Standard Brick Features Included</p>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td width={24} valign="middle">
                                              <img
                                                src={checkIcon}
                                                style={{ verticalAlign: 'baseline' }}
                                                width={16}
                                                alt=""
                                              />
                                            </td>
                                            <td>
                                              <p>Dedicated Engineer at Work Site</p>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td width={24} valign="middle">
                                              <img
                                                src={checkIcon}
                                                style={{ verticalAlign: 'baseline' }}
                                                width={16}
                                                alt=""
                                              />
                                            </td>
                                            <td>
                                              <p>Priority After Support</p>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td width={24} valign="middle">
                                              <img
                                                src={checkIcon}
                                                style={{ verticalAlign: 'baseline' }}
                                                width={16}
                                                alt=""
                                              />
                                            </td>
                                            <td>
                                              <p>Priority Bricks Shipment</p>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td width={24} valign="middle">
                                              <img
                                                src={checkIcon}
                                                style={{ verticalAlign: 'baseline' }}
                                                width={16}
                                                alt=""
                                              />
                                            </td>
                                            <td>
                                              <p>Priority Labour Arrangement</p>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td width={24} valign="middle">
                                              <img
                                                src={checkIcon}
                                                style={{ verticalAlign: 'baseline' }}
                                                width={16}
                                                alt=""
                                              />
                                            </td>
                                            <td>
                                              <p>Priority Shipment Completion</p>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                            <td style={{ background: '#ffffff' }} width={40}>&nbsp;</td>
                            {/* Prime table end */}
                          </tr>
                          <tr>
                            <td style={{ background: '#ffffff' }}>&nbsp;</td>
                            <td style={{ background: '#ffffff' }}>&nbsp;</td>
                            <td style={{ background: '#ffffff' }}>&nbsp;</td>
                            <td style={{ background: '#ffffff' }}>&nbsp;</td>
                            <td style={{ background: '#ffffff' }}>&nbsp;</td>
                          </tr>
                          <tr>
                            <td style={{ background: '#ffffff' }} />
                            <td style={{ background: '#dedfdf', height: 2 }} />
                            <td style={{ background: '#ffffff' }} />
                            <td style={{ background: '#dedfdf', height: 2 }} />
                            <td style={{ background: '#ffffff' }} />
                          </tr>
                          <tr>
                            <td style={{ background: '#ffffff' }}>&nbsp;</td>
                            <td style={{ background: '#ffffff' }}>&nbsp;</td>
                            <td style={{ background: '#ffffff' }}>&nbsp;</td>
                            <td style={{ background: '#ffffff' }}>&nbsp;</td>
                            <td style={{ background: '#ffffff' }}>&nbsp;</td>
                          </tr>
                          <tr>
                            <td style={{ background: '#ffffff' }} />
                            <td style={{ background: '#ffffff' }}>
                              <button
                                style={{
                                  background: '#bc1823',
                                  fontSize: 20,
                                  color: 'white',
                                  width: '100%',
                                  fontWeight: 400,
                                  padding: '0px  12px 18px',
                                  border: 'none',
                                  cursor: 'pointer',
                                }}
                              >
                                Contact Us
                              </button>
                            </td>
                            <td style={{ background: '#ffffff' }} />
                            <td style={{ background: '#ffffff' }}>
                              <button
                                style={{
                                  background: '#bc1823',
                                  fontSize: 20,
                                  color: 'white',
                                  width: '100%',
                                  fontWeight: 400,
                                  padding: '0px  12px 18px',
                                  border: 'none',
                                  cursor: 'pointer',
                                }}
                              >
                                Contact Us
                              </button>
                            </td>
                           
                            <td style={{ background: '#ffffff' }} />
                          </tr>
                          <tr>
                            <td
                              colSpan={5}
                              style={{
                                background: '#ffffff',
                                borderBottomLeftRadius: 15,
                                borderBottomRightRadius: 15,
                              }}
                            >
                              &nbsp;
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                  <tr>
                    <td align="center">
                      <img src="images/footer-bg.jpg" alt="" />
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
            <td style={{ position: 'relative', width: '150px' }}>
              <img
                src={logo2}
                width={114}
                style={{ position: 'absolute', top: 0, right: 125, width: '114px', height: '95px' }}
                alt=""
              />
              <img
                src={logo3}
                width={112}
                style={{ position: 'absolute', top: 0, right: 0,  width: '112px', height: '94px' }}
                alt=""
              />
              
            </td>
          </tr>
        </tbody>
      </table>
      {/* 8inch Dolfix Expert Brick Laying End */}
      <table style={{width:"100%"}}>
        <tbody>
        <tr>
          <td style={{height:"70mm"}}>&nbsp;</td>
        </tr>
        </tbody>
      </table>
      {/* 6inch Dolfix Expert Brick Laying Start */}
      <table
        width={1300}
        style={{
          background: '#f4f6fc',
          paddingTop: 16,
          paddingRight: 16,
          paddingLeft: 16,
        }}
        cellPadding={0}
        cellSpacing={0}
        border={0}
        align="center"
      >
        <tbody>
          <tr>
            <td style={{ position: 'relative', width: '150px' }}>
              <img
                src={logo1}
                width={115}
                style={{ position: 'absolute', top: 0, left: 0, width: '115px', height: '115px' }}
                alt=""
              />
            </td>
            <td>
              <table width={680} cellPadding={0} cellSpacing={0} border={0} align="center">
                <tbody>
                  <tr>
                    <td>
                      <h1
                        style={{
                          fontSize: 38,
                          textAlign: 'center',
                          fontWeight: 800,
                          fontFamily: 'Arial, Helvetica, sans-serif',
                          margin: 0,
                          marginBottom: 10,
                        }}
                      >
                        6inch Dolfix Expert Brick Laying
                      </h1>
                      <h2
                        style={{
                          fontSize: 25,
                          textAlign: 'center',
                          fontWeight: 400,
                          fontFamily: 'Arial, Helvetica, sans-serif',
                          margin: 0,
                          marginBottom: 10,
                        }}
                      >
                        Join <strong>5,000</strong> happy customers
                      </h2>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <table width="100%" cellPadding={0} cellSpacing={0} border={0} align="center">
                        <tbody>
                          <tr>
                            <td
                              colSpan={5}
                              style={{
                                background: '#ffffff',
                                borderTopLeftRadius: 15,
                                borderTopRightRadius: 15,
                              }}
                            >
                              &nbsp;
                            </td>
                          </tr>
                          <tr>
                            {/* standard table start */}
                            <td style={{ background: '#ffffff' }} width={40}>&nbsp;</td>
                            <td style={{ background: '#ffffff' }} width={280} valign="top">
                              <table width="100%" cellPadding={0} cellSpacing={0} border={0}>
                                <tbody>
                                  <tr>
                                    <td style={{textAlign:"left"}}>
                                      <h3
                                        style={{
                                          fontSize: 32,
                                          paddingLeft: 0,
                                          fontWeight: 800,
                                          fontFamily: 'Arial, Helvetica, sans-serif',
                                          color: '#1f1645',
                                          margin: 0,
                                          
                                        }}
                                      >
                                        STANDARD
                                      </h3>
                                      <h4
                                        style={{
                                          fontSize: 24,
                                          fontWeight: 400,
                                          fontFamily: 'Calibri, Helvetica, sans-serif',
                                          color: '#667581',
                                          margin: 0,
                                          marginLeft: 0,
                                          display: 'block',
                                          position: 'relative',
                                          
                                        }}
                                      >
                                        <span style={{position:'relative'}}>
                                        <div
                                          style={{
                                            position: 'absolute',
                                            top: '25px',
                                            height: 5,
                                            width: '80px',
                                            left:'50%',
                                            marginLeft:'-40px',
                                            opacity: '.7',
                                            background: '#dedfdf',
                                          }}
                                        />
                                        <span style={{ fontSize: '90%' }}>₹</span> 96.00
                                        </span>
                                         
                                      </h4>
                                      <h4
                                        style={{
                                          fontSize: 32,
                                          paddingLeft: 0,
                                          fontWeight: 400,
                                          fontFamily: 'Calibri, Helvetica, sans-serif',
                                          color: '#ff0202',
                                          margin: 0,
                                          position: 'relative',
                                          
                                        }}
                                      >
                                        <span
                                          style={{
                                            fontSize: '90%',
                                            color: '#667581',
                                          }}
                                        >
                                          ₹
                                        </span>{' '}
                                        {layingDataObject?.Standard?.['6InchPrice']}
                                      </h4>
                                      <h4
                                        style={{
                                          fontSize: 24,
                                          paddingLeft: 0,
                                          fontWeight: 400,
                                          fontFamily: 'Calibri, Helvetica, sans-serif',
                                          color: '#000000',
                                          margin: 0,
                                          marginBottom: 20,
                                          position: 'relative',
                                          
                                        }}
                                      >
                                        /BRICK
                                      </h4>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <p
                                        style={{
                                          fontSize: 16,
                                          fontWeight: 600,
                                          fontFamily: 'Calibri, Helvetica, sans-serif',
                                          color: '#000000',
                                          margin: 0,
                                          marginBottom: 5,
                                        }}
                                      >
                                        Services Included
                                      </p>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <table
                                        cellPadding={0}
                                        cellSpacing={0}
                                        border={0}
                                        width="100%"
                                        style={{
                                          fontSize: 16,
                                          fontWeight: 400,
                                          fontFamily: 'Calibri, Helvetica, sans-serif',
                                          color: '#000000',
                                          margin: 0,
                                          marginBottom: 5,
                                          lineHeight: '16px',
                                        }}
                                      >
                                        <tbody>
                                          <tr>
                                            <td width={24} valign="middle">
                                              <img
                                                src={checkIcon}
                                                style={{ verticalAlign: 'baseline' }}
                                                width={16}
                                                alt=""
                                              />
                                            </td>
                                            <td>
                                              <p>Expert Mazans</p>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td width={24} valign="middle">
                                              <img
                                                src={checkIcon}
                                                style={{ verticalAlign: 'baseline' }}
                                                width={16}
                                                alt=""
                                              />
                                            </td>
                                            <td>
                                              <p>Expert Helpers</p>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td width={24} valign="middle">
                                              <img
                                                src={checkIcon}
                                                style={{ verticalAlign: 'baseline' }}
                                                width={16}
                                                alt=""
                                              />
                                            </td>
                                            <td>
                                              <p>Engineer Support</p>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td width={24} valign="middle">
                                              <img
                                                src={checkIcon}
                                                style={{ verticalAlign: 'baseline' }}
                                                width={16}
                                                alt=""
                                              />
                                            </td>
                                            <td>
                                              <p>Neat Work</p>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td width={24} valign="middle">
                                              <img
                                                src={checkIcon}
                                                style={{ verticalAlign: 'baseline' }}
                                                width={16}
                                                alt=""
                                              />
                                            </td>
                                            <td>
                                              <p>Excellent Finish</p>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td width={24} valign="middle">
                                              <img
                                                src={checkIcon}
                                                style={{ verticalAlign: 'baseline' }}
                                                width={16}
                                                alt=""
                                              />
                                            </td>
                                            <td>
                                              <p>Less Cement usage</p>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td width={24} valign="middle">
                                              <img
                                                src={checkIcon}
                                                style={{ verticalAlign: 'baseline' }}
                                                width={16}
                                                alt=""
                                              />
                                            </td>
                                            <td>
                                              <p>Less Wastage</p>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td width={24} valign="middle">
                                              <img
                                                src={checkIcon}
                                                style={{ verticalAlign: 'baseline' }}
                                                width={16}
                                                alt=""
                                              />
                                            </td>
                                            <td>
                                              <p>Fast Laying</p>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td width={24} valign="middle">
                                              <img
                                                src={checkIcon}
                                                style={{ verticalAlign: 'baseline' }}
                                                width={16}
                                                alt=""
                                              />
                                            </td>
                                            <td>
                                              <p>Strong & Durable</p>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td width={24} valign="middle">
                                              <img
                                                src={checkIcon}
                                                style={{ verticalAlign: 'baseline' }}
                                                width={16}
                                                alt=""
                                              />
                                            </td>
                                            <td>
                                              <p>After Laying Support</p>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td width={24} valign="middle">
                                              <img
                                                src={checkIcon}
                                                style={{ verticalAlign: 'baseline' }}
                                                width={16}
                                                alt=""
                                              />
                                            </td>
                                            <td>
                                              <p>Pointing work included</p>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                            
                            {/* standard table end */}

                            {/* Prime table start */}
                            <td style={{ background: '#ffffff' }} width={40}>&nbsp;</td>
                            <td style={{ background: '#ffffff' }} width={280} valign="top">
                              <table width="100%" cellPadding={0} cellSpacing={0} border={0}>
                                <tbody>
                                  <tr>
                                    <td style={{textAlign:"left"}}>
                                      <h3
                                        style={{
                                          fontSize: 32,
                                          paddingLeft: 0,
                                          fontWeight: 800,
                                          fontFamily: 'Arial, Helvetica, sans-serif',
                                          color: '#1f1645',
                                          margin: 0,
                                          
                                        }}
                                      >
                                        PRIME
                                      </h3>
                                      <h4
                                        style={{
                                          fontSize: 24,
                                          fontWeight: 400,
                                          fontFamily: 'Calibri, Helvetica, sans-serif',
                                          color: '#667581',
                                          margin: 0,
                                          marginLeft: 0,
                                          display: 'block',
                                          position: 'relative',
                                          
                                        }}
                                      >
                                        <span style={{position:'relative'}}>
                                        <div
                                          style={{
                                            position: 'absolute',
                                            top: '25px',
                                            height: 5,
                                            width: '80px',
                                            left:'50%',
                                            marginLeft:'-40px',
                                            opacity: '.7',
                                            background: '#dedfdf',
                                          }}
                                        />
                                        <span style={{ fontSize: '90%' }}>₹</span> 160.00
                                        </span>
                                        
                                      </h4>
                                      <h4
                                        style={{
                                          fontSize: 32,
                                          paddingLeft: 0,
                                          fontWeight: 400,
                                          fontFamily: 'Calibri, Helvetica, sans-serif',
                                          color: '#ff0202',
                                          margin: 0,
                                          position: 'relative',
                                          
                                        }}
                                      >
                                        <span
                                          style={{
                                            fontSize: '90%',
                                            color: '#667581',
                                          }}
                                        >
                                          ₹
                                        </span>{' '}
                                        {layingDataObject?.Premium?.['6InchPrice']}
                                      </h4>
                                      <h4
                                        style={{
                                          fontSize: 24,
                                          paddingLeft: 0,
                                          fontWeight: 400,
                                          fontFamily: 'Calibri, Helvetica, sans-serif',
                                          color: '#000000',
                                          margin: 0,
                                          marginBottom: 20,
                                          position: 'relative',
                                          
                                        }}
                                      >
                                        /BRICK
                                      </h4>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <p
                                        style={{
                                          fontSize: 16,
                                          fontWeight: 600,
                                          fontFamily: 'Calibri, Helvetica, sans-serif',
                                          color: '#000000',
                                          margin: 0,
                                          marginBottom: 5,
                                        }}
                                      >
                                        Services Included
                                      </p>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <table
                                        cellPadding={0}
                                        cellSpacing={0}
                                        border={0}
                                        width="100%"
                                        style={{
                                          fontSize: 16,
                                          fontWeight: 400,
                                          fontFamily: 'Calibri, Helvetica, sans-serif',
                                          color: '#000000',
                                          margin: 0,
                                          marginBottom: 5,
                                          lineHeight: '16px',
                                        }}
                                      >
                                        <tbody>
                                          <tr>
                                            <td width={24} valign="middle">
                                              <img
                                                src={checkIcon}
                                                style={{ verticalAlign: 'baseline' }}
                                                width={16}
                                                alt=""
                                              />
                                            </td>
                                            <td>
                                              <p>All Standard Brick Features Included</p>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td width={24} valign="middle">
                                              <img
                                                src={checkIcon}
                                                style={{ verticalAlign: 'baseline' }}
                                                width={16}
                                                alt=""
                                              />
                                            </td>
                                            <td>
                                              <p>Dedicated Engineer at Work Site</p>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td width={24} valign="middle">
                                              <img
                                                src={checkIcon}
                                                style={{ verticalAlign: 'baseline' }}
                                                width={16}
                                                alt=""
                                              />
                                            </td>
                                            <td>
                                              <p>Priority After Support</p>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td width={24} valign="middle">
                                              <img
                                                src={checkIcon}
                                                style={{ verticalAlign: 'baseline' }}
                                                width={16}
                                                alt=""
                                              />
                                            </td>
                                            <td>
                                              <p>Priority Bricks Shipment</p>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td width={24} valign="middle">
                                              <img
                                                src={checkIcon}
                                                style={{ verticalAlign: 'baseline' }}
                                                width={16}
                                                alt=""
                                              />
                                            </td>
                                            <td>
                                              <p>Priority Labour Arrangement</p>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td width={24} valign="middle">
                                              <img
                                                src={checkIcon}
                                                style={{ verticalAlign: 'baseline' }}
                                                width={16}
                                                alt=""
                                              />
                                            </td>
                                            <td>
                                              <p>Priority Shipment Completion</p>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                            <td style={{ background: '#ffffff' }} width={40}>&nbsp;</td>
                            {/* Prime table end */}
                          </tr>
                          <tr>
                            <td style={{ background: '#ffffff' }}>&nbsp;</td>
                            <td style={{ background: '#ffffff' }}>&nbsp;</td>
                            <td style={{ background: '#ffffff' }}>&nbsp;</td>
                            <td style={{ background: '#ffffff' }}>&nbsp;</td>
                             
                            <td style={{ background: '#ffffff' }}>&nbsp;</td>
                          </tr>
                          <tr>
                            <td style={{ background: '#ffffff' }} />
                            <td style={{ background: '#dedfdf', height: 2 }} />
                            <td style={{ background: '#ffffff' }} />
                             
                            <td style={{ background: '#dedfdf', height: 2 }} />
                            <td style={{ background: '#ffffff' }} />
                          </tr>
                          <tr>
                            <td style={{ background: '#ffffff' }}>&nbsp;</td>
                            <td style={{ background: '#ffffff' }}>&nbsp;</td>
                             
                            <td style={{ background: '#ffffff' }}>&nbsp;</td>
                            <td style={{ background: '#ffffff' }}>&nbsp;</td>
                            <td style={{ background: '#ffffff' }}>&nbsp;</td>
                          </tr>
                          <tr>
                            <td style={{ background: '#ffffff' }} />
                            <td style={{ background: '#ffffff' }}>
                              <button
                                style={{
                                  background: '#bc1823',
                                  fontSize: 20,
                                  color: 'white',
                                  width: '100%',
                                  fontWeight: 400,
                                  padding: '0px  12px 18px',
                                  border: 'none',
                                  cursor: 'pointer',
                                }}
                              >
                                Contact Us
                              </button>
                            </td>
                            <td style={{ background: '#ffffff' }} />
                            <td style={{ background: '#ffffff' }}>
                              <button
                                style={{
                                  background: '#bc1823',
                                  fontSize: 20,
                                  color: 'white',
                                  width: '100%',
                                  fontWeight: 400,
                                  padding: '0px  12px 18px',
                                  border: 'none',
                                  cursor: 'pointer',
                                }}
                              >
                                Contact Us
                              </button>
                            </td>
                           
                            <td style={{ background: '#ffffff' }} />
                          </tr>
                          <tr>
                            <td
                              colSpan={5}
                              style={{
                                background: '#ffffff',
                                borderBottomLeftRadius: 15,
                                borderBottomRightRadius: 15,
                              }}
                            >
                              &nbsp;
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                  <tr>
                    <td align="center">
                      <img src="images/footer-bg.jpg" alt="" />
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
            <td style={{ position: 'relative', width: '150px' }}>
              <img
                src={logo2}
                width={114}
                style={{  position: 'absolute', top: 0, right: 125, width: '114px', height: '95px' }}
                alt=""
              />
              <img
                src={logo3}
                width={112}
                style={{ position: 'absolute', top: 0, right: 0, width: '112px', height: '94px' }}
                alt=""
              />
              
            </td>
          </tr>
        </tbody>
      </table>
      {/* 6inch Dolfix Expert Brick Laying End */}
    </div>
  );
};

export default HtmlToPdf;