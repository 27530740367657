export const calculateTransportCost = (perKmRateData, margin, distance, wheelsData, brickPricesData) => {
  perKmRateData = Array.isArray(perKmRateData) ? perKmRateData : [perKmRateData];
  console.log("perKmRateData>>>>",perKmRateData)
  return perKmRateData.map((perKmCost) => {
    const perkmPrice = parseInt(perKmCost.per_km_price, 10) || 0;
    const fixedCostPrice =
      Math.round(parseInt(perKmCost.fixed_cost, 10) + parseInt(perKmCost.fixed_cost, 10) * (margin / 100)) || 0;
    const brickRate = Math.round(perkmPrice + perkmPrice * (margin / 100));
    const totalBrickRate = distance > 100 ? brickRate * distance : fixedCostPrice;
    const wheel = wheelsData.find((wheel) => wheel?.truckType.toString() === perKmCost?.truckType.toString());
    const capacity = wheel?.capacity || 0;

    const perTonCost = Math.round(totalBrickRate / parseInt(capacity, 10));

    const bricksQty8Inch = getBrickTonPrice('8', 'interlock', brickPricesData);
    const bricksQty6Inch = getBrickTonPrice('6', 'interlock', brickPricesData);

    const transportCostEightInch = Math.ceil((perTonCost / bricksQty8Inch) * 10) / 10;
    const transportCostSixInch = Math.ceil((perTonCost / bricksQty6Inch) * 10) / 10;

    return {
      wheel: perKmCost.truckType,
      perTonRate: perTonCost,
      eightInch: transportCostEightInch,
      sixInch: transportCostSixInch,
      eightInchWithGST: applyGST(transportCostEightInch).toFixed(2),
      sixInchWithGST: applyGST(transportCostSixInch).toFixed(2),
    };
  });
};

export const calculateTransportCostUnder100KM = (perKmRateData, margin, distance, wheelsData, brickPricesData) => {
  perKmRateData = Array.isArray(perKmRateData) ? perKmRateData : [perKmRateData];
  return perKmRateData.map((perKmCost) => {
    const totalBrickRate = parseInt(perKmCost.fixed_cost, 10) || 0;
    const wheel = wheelsData.find((wheel) => wheel.truckType.toString() === perKmCost.truckType.toString());
    const capacity = wheel?.capacity || 0;

    const perTonCost = Math.round(totalBrickRate / parseInt(capacity, 10));

    const bricksQty8Inch = getBrickTonPrice('8', 'interlock', brickPricesData);
    const bricksQty6Inch = getBrickTonPrice('6', 'interlock', brickPricesData);

    const transportCostEightInch = Math.ceil((perTonCost / bricksQty8Inch) * 10) / 10;
    const transportCostSixInch = Math.ceil((perTonCost / bricksQty6Inch) * 10) / 10;

    return {
      wheel: perKmCost.truckType,
      perTonRate: perTonCost,
      eightInch: transportCostEightInch,
      sixInch: transportCostSixInch,
      eightInchWithGST: applyGST(transportCostEightInch).toFixed(2),
      sixInchWithGST: applyGST(transportCostSixInch).toFixed(2),
    };
  });
};

export const applyGST = (value) => value + value * 0.12;

export const createBrickPriceObj = (brickPricesData) => {
  return brickPricesData.reduce(
    (result, item) => {
      if (item.brickQuality === 'standard' || item.brickQuality === 'prime' || item.brickQuality === 'super') {
        const key = `${item.brickQuality[0].toUpperCase()}${item.brickQuality.slice(1)}`;
        const size = item.brickSize === '8' ? 'eightInch' : 'sixInch';
        result[key][size] = parseInt(item.brickPrice, 10) || 0;
      }
      return result;
    },
    {
      Standard: { eightInch: 0, sixInch: 0 },
      Prime: { eightInch: 0, sixInch: 0 },
      Super: { eightInch: 0, sixInch: 0 },
    }
  );
};

export const getBrickTonPrice = (size, type, brickPricesData) => {
  const bricksQty = brickPricesData.find((brick) => brick.brickSize === size && brick.brickType === type);
  return bricksQty ? bricksQty.bricksPerTon : null;
};

export const convertValue = (value) => {
  const priceArr = JSON.stringify(value).split('.');
  let finalPrice = 0;
  if (parseInt(priceArr[1], 10) > 222) {
    finalPrice = (Math.floor(priceArr[0]) + 0.88).toFixed(2);
  } else if (parseInt(priceArr[1], 10) < 222) {
    finalPrice = (Math.floor(priceArr[0]) - 1 + 0.99).toFixed(2);
  } else if (parseInt(priceArr[1], 10) > 888) {
    finalPrice = (Math.floor(priceArr[0]) + 0.99).toFixed(2);
  } else {
    finalPrice = (Math.floor(priceArr[0]) - 1 + 0.99).toFixed(2);
  }
  // Return a default value (you can change this to suit your needs)
  return finalPrice;
};
