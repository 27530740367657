import React from 'react'

const TransportCost = ({type, title, finalResult}) => {
  return (
    <div className="table-container table-data-sm mt-10 mb-60">
        <h3>{title}</h3>
        <div className="brick-rates">
          <div className="brick-type">
            <div className="brick-sizes">
              <div className="brick-size">
                <p>Brick Size</p>
                <p className="price">6 Inch</p>
                <p className="price">8 Inch</p>
              </div>
              {finalResult.length > 0 ? (
                finalResult.map((cost,index) => (
                  <div key={index} className="brick-size">
                    <p>{cost?.wheel}W</p>
                    <p className="price">
                      <span> ₹ </span>
                      {type==='excludeGST' && cost?.sixInch}
                      {type==='includeGST' && cost?.sixInchWithGST}
                    </p>
                    <p className="price">
                      <span> ₹ </span>
                      {type==='excludeGST' && cost?.eightInch}
                      {type==='includeGST' && cost?.eightInchWithGST}
                    </p>
                  </div>
                ))
              ) : (
                <>
                  <div className="brick-size">
                    <p>6W</p>
                    <p className="price">
                      <span> ₹ </span>0
                    </p>
                    <p className="price">
                      <span> ₹ </span>0
                    </p>
                  </div>
                  <div className="brick-size">
                    <p>10W</p>
                    <p className="price">
                      <span> ₹ </span>0
                    </p>
                    <p className="price">
                      <span> ₹ </span>0
                    </p>
                  </div>
                  <div className="brick-size">
                    <p>12W</p>
                    <p className="price">
                      <span> ₹ </span>0
                    </p>
                    <p className="price">
                      <span> ₹ </span>0
                    </p>
                  </div>
                  <div className="brick-size">
                    <p>14W</p>
                    <p className="price">
                      <span> ₹ </span>0
                    </p>
                    <p className="price">
                      <span> ₹ </span>0
                    </p>
                  </div>
                  <div className="brick-size">
                    <p>16W</p>
                    <p className="price">
                      <span> ₹ </span>0
                    </p>
                    <p className="price">
                      <span> ₹ </span>0
                    </p>
                  </div>
                  <div className="brick-size">
                    <p>18W</p>
                    <p className="price">
                      <span> ₹ </span>0
                    </p>
                    <p className="price">
                      <span> ₹ </span>0
                    </p>
                  </div>
                  <div className="brick-size">
                    <p>22W</p>
                    <p className="price">
                      <span> ₹ </span>0
                    </p>
                    <p className="price">
                      <span> ₹ </span>0
                    </p>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
  )
}

export default TransportCost