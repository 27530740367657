

export const FILTER_LIST = {
  all: 'All',
  active: 'Active',
  inactive: 'Inactive',
  rejected: 'Rejected',
};

export const emojiRegex = /[\uD800-\uDFFF\u2600-\u27FF\u2B50\u2B55]/g;

export const BRICKS_QUALITY = {
  standard: 'Standard',
  super: 'Super',
  prime: 'Prime',
};

export const BRICKS_TYPES = {
  m: 'M',
  interlock: 'INTERLOCK',
};

export const BRICKS_SIZE = {
  6: '6',
  8: '8',
};

export const LAYING_TYPE = {
  Standard: 'STANDARD',
  Premium: 'PREMIUM',
};

export const TRUCK_TYPES = [6, 10, 12, 14, 16, 18, 22];

export const STATUS = {
  active: 'Active',
  inactive: 'Inactive',
}
