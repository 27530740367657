import { filter } from 'lodash';

export const getComparator = (order, orderBy) => (
    order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy))



export const descendingComparator = (a, b, orderBy) => {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

export const applySortFilter = (array, comparator, query, keyName) => {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    if (query) {
        return filter(
            stabilizedThis.map((el) => el[0]),
            (_user) => _user[keyName].toLowerCase().indexOf(query.toLowerCase()) !== -1
        );
        // return filter(array, (_user) => _user[keyName].toLowerCase().indexOf(query.toLowerCase()) !== -1);
    }
    return stabilizedThis.map((el) => el[0]);
}


export const getAvatarText = (name) => {
    const nameArr = name.split(' ');
    const firstName = nameArr[0] || '';
    const lastName = nameArr[1] || '';
    return `${firstName[0] || ''}${lastName[0] || ''}`?.toUpperCase();
};


export const stringToColor = (string) => {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (i = 0; i < 3; i += 1) {
        const value = (hash >> (i * 8)) & 0xff;
        color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
}