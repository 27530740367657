import React from 'react';

const BrickLayingCost = ({ layingType, brickData }) => {
  return (
    <div className="brick-type" key={layingType}>
      <h3>{layingType === 'Premium' ? 'Prime' : layingType}</h3>
      <div className="brick-sizes">
        {brickData
          .filter((brick) => brick.layingType === layingType)
          .map((brick) => (
            <div className="brick-size" key={brick.brickSize}>
              <p>{`${brick.brickSize} Inch:`}</p>
              <p className="price">
                <span>&#8377; <em style={{color:"#bc1823", fontStyle:"normal"}}>{brick.perBrickCost}</em></span>
              </p>
            </div>
          ))}
      </div>
    </div>
  );
};

export default BrickLayingCost;
